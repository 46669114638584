import {
  BooleanFilter,
  CheckFilter,
  ComboFilter,
  OpFilter,
  withFilter,
} from ".";

export function createCollegue(mediator, name, config) {
  const { type, icon, ...props } = config;
  const WrappedFilter =
    type === "operator"
      ? OpFilter
      : type === "select"
      ? ComboFilter
      : type === "check"
      ? CheckFilter
      : BooleanFilter;

  const collegue = {
    id: name,
    createElement: (name) => {
      const ProxyComponent = withFilter(WrappedFilter, props);
      return (
        <ProxyComponent
          key={name}
          icon={icon}
          name={`${name}-filter`}
          onNotify={(updater) => mediator.notify(updater)}
        />
      );
    },
  };

  return collegue;
}
