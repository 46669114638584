import { Route, Routes } from "react-router-dom";
import { Animate, Layout, PersistLogin } from "./components";
import * as Page from "./pages";
import Channels from "./pages/channels/Channels";
import Settings from "./pages/settings/Settings";
import RequireAuth from "./services/RequireAuth";
import { ReportProvider } from "./store/contexts";

function createElment(ChildPage) {
  return <Page.AnimatedPage element={<ChildPage />} />;
}

const App = () => {
  const dashboard = <Page.AnimatedPage element={<Page.Dashboard />} />;
  const serverPage = <Page.AnimatedPage element={<Page.Servers />} />;

  const settingsPage = (
    <Animate fade>
      <Settings />
    </Animate>
  );
  const reportPage = (
    <Page.AnimatedPage
      element={
        <ReportProvider>
          <Page.Reports />
        </ReportProvider>
      }
    />
  );

  return (
    <Routes>
      <Route exact patth="/" element={<Layout />} />
      {/* public routes */}
      <Route index element={<Page.Login />} />
      <Route path="expired" element={<Page.Expired />} />

      {/* protected routes */}
      <Route path="repo" element={<Page.Home />}>
        <Route path="unauthorized" element={<Page.Unauthorized />} />

        <Route element={<PersistLogin />}>
          {/** SuperAdmin routes */}
          <Route element={<RequireAuth allowedRank={3} />}>
            <Route path="settings/servers" element={serverPage} />
          </Route>

          {/** Admin routes */}
          <Route element={<RequireAuth allowedRank={2} />}>
            <Route path="settings/users/*" element={createElment(Page.Users)} />
          </Route>

          {/** SuperUser routes */}
          <Route element={<RequireAuth allowedRank={1} />}>
            <Route index element={dashboard} />
            <Route path="analysis" element={createElment(Page.Analysis)} />
            <Route path="dashboard" element={dashboard} />
            <Route path="settings" element={settingsPage} />
            <Route path="channels" element={createElment(Channels)} />
            <Route path="feeds" element={createElment(Page.Feeds)} />
            <Route path="interfaces" element={createElment(Page.Interfaces)} />
            <Route path="facilities" element={createElment(Page.Locations)} />
            <Route path="reports" element={reportPage} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Page.NotFound />} />
    </Routes>
  );
};

export default App;
