import {
  BsCpu as CWIcon,
  BsAlt as TCPIcon,
  BsCodeSlash as JSIcon,
  BsFonts as FWIcon,
  BsActivity as JMSIcon,
  BsFiletypeDoc as DocIcon,
  BsHddNetwork as HTTPIcon,
  BsPaperclip as SMTPIcon,
} from "react-icons/bs";

export function renderConnector(dest) {
  switch (dest.connector_type) {
    case "TCP Sender":
      return [
        <span>
          <p>{dest.name}</p>Port {dest.port}
        </span>,
        <TCPIcon size={20} />,
      ];
    case "Channel Writer":
      return [
        <span className="leading-tight">
          <p>{dest.name}</p>
          <span className="text-[15px] tracking-tight">{dest.uuid}</span>
        </span>,
        <CWIcon size={20} />,
      ];
    case "Document Writer":
      return [
        <div>
          <p>{dest.name}</p>
          <p className="text-xs truncate">{dest.directory}</p>
        </div>,
        <DocIcon size={20} />,
      ];
    case "File Writer":
      let protocol = `${dest.protocol} ${dest.name}`;
      return [
        <span>
          <p>{protocol}</p>
          <p>host {dest.host}</p>
        </span>,
        <FWIcon size={20} />,
      ];
    case "HTTP Sender":
      return [
        <span>
          <p>{dest.name}</p>
          <p className="text-xs truncate">{dest.url}</p>
        </span>,
        <HTTPIcon size={20} />,
      ];
    case "JavaScript Writer":
      return [<span>{dest.goal}</span>, <JSIcon size={20} />];
    case "JMS Sender":
      return [
        <span>
          <p>{dest.name}</p>destination {dest.destination}
        </span>,
        <JMSIcon size={20} />,
      ];
    case "SMTP Sender":
      return [null, <SMTPIcon size={20} />];
    default:
      return [null, null];
  }
}
