import { useCallback, useEffect, useRef } from "react";

/**
 * Custom hook that returns a boolean value
 * indicating whether the component is mounted.
 */
const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};

export default useIsMounted;
