import { useCallback, useState } from "react";
import { Toast } from "../../components";

const initialToast = { message: null, isWarning: false };

const useToast = (id) => {
  const [{ message, isWarning }, setMessage] = useState(initialToast);

  const clearHandler = useCallback(() => setMessage(initialToast), []);

  const infoHandler = useCallback((message) => setMessage({ message }), []);

  const warnHandler = useCallback(
    (message) => setMessage({ message, isWarning: true }),
    []
  );

  return {
    isError: isWarning && message,
    element: <Toast id={id} warning={isWarning} message={message} />,
    info: infoHandler,
    warn: warnHandler,
    clear: clearHandler,
  };
};

export default useToast;
