import { MdOpenInNew } from "react-icons/md";
import { RiUser6Fill as UserIcon } from "react-icons/ri";
import { IconAvatar, IconButton, Indicator, RepoTooltip } from "../components";

export function getUserTableConfig(onViewProfile) {
  return [
    {
      key: "user-select",
      render: (user) => {
        return (
          <div>
            <RepoTooltip label="Open" position="right">
              <IconButton
                icon={
                  <MdOpenInNew
                    color="primary"
                    size={22}
                    onClick={() => onViewProfile(user.publicId)}
                  />
                }
              />
            </RepoTooltip>
          </div>
        );
      },
      align: "align-center",
    },
    {
      label: "Display Name",
      render: (user) => (
        <div className="flex space-x-2 items-center py-2">
          <IconAvatar Icon={UserIcon} size={20} color="purple" />
          <div>
            <p>{user.display}</p>
            <p className="sm-text">{user.email}</p>
          </div>
        </div>
      ),
      sortValue: (user) => user.display,
    },
    {
      label: "Department",
      render: (user) => (
        <span className="tracking-tight ml-1">{user.department}</span>
      ),
      sortValue: (user) => user.department,
    },
    {
      label: "User Role",
      render: (user) => user.role,
      sortValue: (user) => user.role,
    },
    {
      label: "Status",
      render: (user) => (
        <div className="flex-j">
          <Indicator color={user.status === "A" ? "ok" : "danger"} />
        </div>
      ),
      align: "text-center",
    },
    {
      label: "Last Active On",
      render: (user) => <span className="ml-1">{user.lastLogin}</span>,
      sortValue: (user) => user.lastLogin,
    },
  ];
}
