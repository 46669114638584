import { useCallback, useEffect, useState } from "react";
import { PiUsersDuotone as UserIcon } from "react-icons/pi";
import "../../assets/css/users.main.css";
import { Panel, PanelHeader, TabContent, Tabs } from "../../components";
import { useEiirQuery } from "../../repo-query";
import { handleSearch, Urls } from "../../store/axios";
import {
  useAuth,
  useAxiosPrivate,
  useNavigateHistory,
} from "../../store/hooks";
import UserList from "./UserList";

const queryKey = "users";
const initialState = { page: 1, pageSize: 12 };

const Users = () => {
  const [userTerm, setUserTerm] = useState(initialState);
  const [userId, setUserId] = useState(null);
  const { location } = useNavigateHistory();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const { data, isLoading, refetch } = useEiirQuery({
    queryKey: [queryKey, { ...userTerm }],
    queryFn: async () =>
      await axiosPrivate.post(Urls.search[queryKey], userTerm),
    enabled: false,
  });

  const handleView = (userId = null) => setUserId(userId);

  const handleCreated = useCallback(
    () => refetch(),
    // eslint-disable-next-line
    []
  );

  const handleChange = useCallback((name) => {
    const state = name
      ? {
          ...initialState,
          filter: { term: { operator: "contains", value: name } },
        }
      : initialState;
    setUserTerm(state);
  }, []);

  useEffect(() => {
    const lastPath = location.split("/").pop();
    setUserId(
      lastPath === "profile"
        ? auth.publicId
        : lastPath === "users"
        ? null
        : lastPath
    );
  }, [location, auth.publicId]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [userTerm]);

  const users = handleSearch(data);

  const paginationState = {
    mediator: { notify: (updater) => setUserTerm((cur) => updater(cur)) },
    totalCount: users.total || 0,
    currPage: userTerm.page,
    pageSize: userTerm.pageSize,
  };

  return (
    <>
      <Panel>
        <PanelHeader
          PanelIcon={UserIcon}
          title="User Management"
          subtitle="Accounts"
        />
      </Panel>
      <Tabs>
        <TabContent label="Users">
          <UserList
            isLoading={isLoading}
            profileId={userId}
            users={users}
            pagination={paginationState}
            onCreated={handleCreated}
            onChange={handleChange}
            onView={handleView}
          />
        </TabContent>
      </Tabs>
    </>
  );
};

export default Users;
