import { Outlet } from "react-router-dom";
import { Breadcrumb } from ".";
import { useAleiirt } from "../store/hooks";

const Layout = () => {
  const { repoAlert } = useAleiirt();

  return (
    <div className="outlet">
      {repoAlert}
      <Breadcrumb />
      <Outlet />
    </div>
  );
};

export default Layout;
