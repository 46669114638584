import { useEffect, useState } from "react";
import { FiCheckCircle as SuccessIcon } from "react-icons/fi";
import { LuFolderSync as UpdateIcon } from "react-icons/lu";
import { Button } from "../../components";
import { useEiirMutation } from "../../repo-query";
import { useAxiosPrivate, useCallWithRetry, useToast } from "../../store/hooks";
import useIsMounted from "../../store/hooks/use-ismounted";

const ServerStatus = {
  Ready: "R",
  Active: "A",
  Inactive: "I",
  Staged: "S",
};

export const RepositoryBuild = ({ server, onClose }) => {
  const [isBuilding, setIsBuilding] = useState(false);
  const [complete, setComplete] = useState(false);
  const { element, warn } = useToast();
  const axiosPrivate = useAxiosPrivate();
  const isMounted = useIsMounted();

  const { withRetry } = useCallWithRetry({
    predicate: (syncUrl) =>
      new Promise(async (resolve, reject) => {
        try {
          const { data } = await axiosPrivate(syncUrl);
          const { status, detail } = data;
          if (status.code === "S") {
            setComplete(true);
            return resolve(true);
          } else if (status.code === "W") return resolve(false);
          else return reject(detail);
        } catch (error) {
          return reject(error);
        }
      }),
    onComplete: null,
    onSettled: () => {
      setIsBuilding(false);
    },
  });

  const builder = {
    mutationKey: ["buildFeeds"],
    mutationFn: async () =>
      await axiosPrivate.post(`/mirth/servers/${server.serverId}/services`),
    onSuccess: ({ data }) => {
      const { dataImport } = data || {};
      const syncUrl = `/mirth/servers/${server.serverId}/staging/${dataImport.stagingId}`;
      setIsBuilding(true);
      withRetry(syncUrl);
    },
    onError: ({ message }) => {
      warn(message);
      setIsBuilding(false);
    },
    retry: false,
  };

  useEffect(() => {
    if (isMounted()) {
      if (server.status !== ServerStatus.Active)
        warn(
          <>
            <p>There are uncommitted work pending.</p>
            <p>Please try again later!</p>
          </>
        );
    }
  }, [isMounted, warn, server]);

  const buildMutation = useEiirMutation(builder);

  return (
    <div className="flex-ij-col space-y-3 mb-4">
      {complete ? (
        <>
          <span className="text-green-600">
            <SuccessIcon size={48} />
          </span>
          <p className="font-semibold">Build completed successfully.</p>
          <Button content="Close Window" onClick={onClose} />
        </>
      ) : (
        <>
          <span className="text-secondary">
            <UpdateIcon size={48} />
          </span>
          <div className="text-center font-semibold">
            <span className="text-sm">Server Synchronization</span>
            <p>{server.name}</p>
          </div>

          {server.status === ServerStatus.Active && (
            <>
              <p className="text-center leading-tight">
                Click the button below to sync the EIIR Repository
                <br />
                <span>with the latest changes</span>
              </p>

              <Button
                content="Build Repository"
                onClick={() => buildMutation.mutate()}
                loading={buildMutation.isLoading || isBuilding}
                disabled={server.status !== ServerStatus.Active}
              />
            </>
          )}

          {element}
        </>
      )}
    </div>
  );
};
