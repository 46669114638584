import { useCallback, useState } from "react";

const useLocalStorage = (initialStorage = {}) => {
  // Get the initial value from local storage
  // or use the provided initialValue
  const [storage, setStorage] = useState(() => {
    const storageObj = {};
    for (let [initialKey, initialValue] of Object.entries(initialStorage)) {
      const storedItem = localStorage.getItem(initialKey);
      storageObj[initialKey] = storedItem
        ? JSON.parse(storedItem)
        : initialValue;
    }

    return storageObj;
  });

  const setStorageItem = useCallback((key, value) => {
    // save to local storage
    localStorage.setItem(key, JSON.stringify(value));
    // save state
    setStorage((prevStorage) => ({ ...prevStorage, [key]: value }));
  }, []);

  return [storage, setStorageItem];
};

export default useLocalStorage;
