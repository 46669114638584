import { useCallback, useState } from "react";
import {
  MdToll as ChannelsIcon,
  MdSettingsEthernet as ConnectorIcon,
  MdDevicesOther as ServersIcon,
  MdPublishedWithChanges as StatusIcon,
} from "react-icons/md";
import { PiChartBarDuotone as ReportIcon } from "react-icons/pi";
import { Button, MiniCard, MiniOption } from "../../components";
import { Urls } from "../../store/axios";
import {
  useAleiirt,
  useAxiosPrivate,
  useCommon,
  useDropContainer,
  useReport,
} from "../../store/hooks";
import { ReportConnectorForm } from "./ReportConnectorForm";
import { useEiirMutation } from "../../repo-query";

export const ChannelsReports = ({ onAlert }) => {
  const axiosPrivate = useAxiosPrivate();
  const { sources } = useCommon();
  const [servers] = useState("All");
  const [channels] = useState("All");
  const [connector, setConnector] = useState({ status: "All" });
  const [status, setStatus] = useState("All");
  const { setErrorMessage } = useAleiirt();
  const { xportControl, channelsToCSV } = useReport();
  const [droparea, divRef, showContainer, closeWindow] =
    useDropContainer("channels-rpt");

  const channelReport = {
    mutationKey: ["channel-rpts"],
    mutationFn: async (filters) =>
      await axiosPrivate.post(Urls.reports.channels, filters),
    onSuccess: (data) => {
      connector.clearHandler && connector.clearHandler();
      Promise.resolve(data)
        .then((data) => {
          const csv = channelsToCSV(data);
          xportControl.exportMultiCSV(csv, "mirthchannels-rpt.csv");
        })
        .finally(() => {
          // reset form
          setStatus("All");
          setConnector({ status: "All" });
        });
    },
    onError: ({ message }) => setErrorMessage(message),
  };

  const mutation = useEiirMutation(channelReport);

  const handleDownload = useCallback(async () => {
    const filters = {};

    if (status !== "All") filters.status = status;
    if (connector.status !== "All") {
      filters.connectors = [];
      sources.forEach((s, i) => {
        if (connector.values[i]) filters.connectors.push(sources[i].alias);
      });
    }

    mutation.mutate(filters);

    // eslint-disable-next-line
  }, [status, connector, sources]);

  const handleAccept = useCallback(
    (values, cb) => {
      const checks = Object.values(values);
      const isAll = checks.every(Boolean) || checks.every((s) => !s);
      setConnector({
        values,
        status: isAll ? "All" : "Some",
        clearHandler: cb,
      });
      closeWindow();
    },
    [closeWindow]
  );

  const connectorsHandler = useCallback(() => {
    showContainer({
      content: (
        <ReportConnectorForm
          connectors={sources}
          onClose={closeWindow}
          onAccept={handleAccept}
        />
      ),
    });

    // eslint-disable-next-line
  }, [handleAccept, closeWindow, sources]);

  const statusHandler = useCallback(() => {
    setStatus((prevStatus) =>
      prevStatus === "All"
        ? "Active"
        : prevStatus === "Active"
        ? "Inactive"
        : "All"
    );
  }, []);

  return (
    <div className="col-span-2 ml-5 pl-5">
      <div className="flex space-x-5 ml-5 w-[80%]">
        <span className="w-12 h-12 p-2 flex-ij rounded-full border border-slate-400 bg-neutral-50 drop-shadow-sm">
          <ReportIcon color="green" size={36} />
        </span>
        <div className="text-[14px] leading-tight">
          <span className="font-semibold">Mirth Channels Report</span>
          <p>
            Export a report of all the deployed channels from one or more Mirth
            server intances filtered by connector types and/or active statuses.
          </p>
          <p className="mt-1.5">
            Use the filters available below to narrow down your selection.
          </p>
          <div className="relative">
            <MiniCard>
              <MiniOption label="Servers" value={servers} Icon={ServersIcon} />
              <div id="connectors-rpt" ref={divRef} className="relative">
                <MiniOption
                  label="Connectors"
                  value={connector.status}
                  Icon={ConnectorIcon}
                  callback={connectorsHandler}
                />
                {droparea}
              </div>
              <MiniOption
                label="Status"
                value={status}
                Icon={StatusIcon}
                callback={statusHandler}
              />
              <MiniOption
                label="Channels"
                value={channels}
                Icon={ChannelsIcon}
              />
            </MiniCard>
          </div>
          <div className="mt-4">
            <Button
              content="Download Results"
              onClick={handleDownload}
              loading={mutation.isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
