import { useEffect, useRef } from "react";

/**
 * Run a function once on mount.
 * @param {EffectCallback} cb - callback function that will run.
 */
const useOnce = (cb) => {
  const triggered = useRef(false);

  useEffect(() => {
    if (triggered.current) return;
    triggered.current = true;

    cb();
    // eslint-disable-next-line
  }, []);
};

export default useOnce;
