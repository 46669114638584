import { memo } from "react";
import { Card, Dropdown, PaginationNav } from ".";

const limits = [12, 24, 48];

const Pagination = (props) => {
  const { mediator, ...rest } = props;

  const handlePageChange = (value) => {
    mediator.notify((oldState) => ({ ...oldState, page: value }));
  };

  const handleLimitChange = (_, value) => {
    mediator.notify((oldState) => {
      const lastExpected = Math.ceil(rest.totalCount / value);
      return {
        ...oldState,
        pageSize: value,
        page: oldState.page > lastExpected ? lastExpected : oldState.page,
      };
    });
  };

  return (
    <Card>
      <div className="paginate">
        <div className="page-entry">
          <Dropdown
            id="pagentry"
            top
            outline
            value={rest.pageSize}
            sourceItems={limits}
            onChange={handleLimitChange}
          />
          <span className="tsnw tt">/page</span>
        </div>
        <PaginationNav display {...rest} onChange={handlePageChange} />
      </div>
    </Card>
  );
};

export default memo(Pagination);
