import { useState } from "react";
import { channelGroupsColumns } from "../../columns/cg-columns";
import { Button, Table } from "../../components";
import { useEiirMutation } from "../../repo-query";
import { useAlert, useAxiosPrivate } from "../../store/hooks";
import { MirthLoginForm } from "./MirthLoginForm";

const DownloadGroupsForm = ({
  serverId,
  serverName,
  onInvalidate,
  onClose,
}) => {
  const [session, setSession] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [groups, setGroups] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const alerter = useAlert("mirth-client-api");

  const serverUrlPrefix = `/mirth/servers/${serverId}`;

  const { logOut, stageGroups, downloadGroups } = {
    logOut: {
      mutationKey: ["logoutMirth"],
      mutationFn: async (data) =>
        axiosPrivate.post(`${serverUrlPrefix}/logout`, data),
    },
    stageGroups: {
      mutationkey: ["stageGroups"],
      mutationFn: async ({ sessionId, data }) =>
        await axiosPrivate.post(`${serverUrlPrefix}/staging`, {
          session: sessionId,
          selectedGroups: data,
        }),
      onSuccess: () => {
        onInvalidate();
        onClose();
      },
      onError: (error) => {
        alerter.setErrorMessage(error.message);
      },
    },
    downloadGroups: {
      mutationKey: ["importGroups"],
      mutationFn: async (creds) =>
        await axiosPrivate.post(`${serverUrlPrefix}/channelgroups`, creds),
      onSuccess: ({ sessionId, groups }) => {
        let numChannels = 0,
          checked = false;

        let items = groups.map((g, i) => {
          numChannels += g.channels.count;
          return {
            id: g.uuid,
            name: g.name,
            count: g.channels.count,
            imported: g.channels.imported,
            checked,
          };
        });

        items = items.slice(0, 9);

        setGroups(items);
        setSession({ id: sessionId, numChannels });
        setGroups(groups);
      },
      onError: (error) => alerter.setErrorMessage(error.message),
    },
  };

  const logOutMutation = useEiirMutation(logOut);
  const stageMutation = useEiirMutation(stageGroups);
  const downloadMutation = useEiirMutation(downloadGroups);

  const handleItemChange = (id, checked) => {
    if (id === "chk-all") {
      setSelectAll((prev) => !prev);
      setGroups((prevItems) =>
        prevItems.map((item) =>
          item.channels.count === 0 ? item : { ...item, checked }
        )
      );
    } else
      setGroups((prevItems) =>
        prevItems.map((item, index) =>
          index === id && item.channels.count !== 0
            ? { ...item, checked }
            : item
        )
      );
  };

  const handleCancel = async () => {
    // cancelQuery("groups");
    logOutMutation.mutate({ session: session.id });
  };

  const handleAuthorize = async (username, password) =>
    downloadMutation.mutate({ username, password });

  const handleStageImport = () => {
    const data = groups.filter((g) => g.checked).map((g) => g.uuid);
    stageMutation.mutate({ sessionId: session.id, data });
  };

  const itemCount = groups.reduce(
    (sum, item) => sum + (item.checked ? 1 : 0),
    0
  );

  const content =
    groups.length > 0 ? (
      <div className="drawer-section px-2.5">
        {alerter.repoAlert}
        <main className="h-[84%]">
          <div className="tsnw">
            {`${groups.length} Groups [ ${session.numChannels} Channels ]`}
          </div>
          <Table
            sx={{ maxHeight: "82vh" }}
            dataSource={groups}
            columns={channelGroupsColumns(selectAll, handleItemChange)}
          />
        </main>
        <div className="flexe space-x-6 py-2">
          <span>{itemCount} Groups selected</span>
          <Button
            id="cancel-btn"
            outline
            content="Cancel"
            onClick={() => {
              handleCancel();
              onClose();
            }}
          />
          <Button
            id="import-btn"
            content="Stage Groups"
            disabled={!itemCount}
            loading={stageMutation.isLoading}
            onClick={handleStageImport}
          />
        </div>
      </div>
    ) : (
      <section className={`${downloadMutation.isLoading && "opacity-scale"}`}>
        <MirthLoginForm
          serverName={serverName}
          title={
            downloadMutation.isLoading
              ? "Logging into Mirth instance"
              : "Download Channel Groups"
          }
          loading={downloadMutation.isLoading}
          onAuthorize={handleAuthorize}
        />
      </section>
    );

  return <div className="drawer-form">{content}</div>;
};

export default DownloadGroupsForm;
