import { useCallback } from "react";
import { BUFFER_SIZE, useAleiirt, useAxiosPrivate } from ".";
import { useEiirMutation } from "../../repo-query";

const useUpload = (uploadKey, pathname, callback) => {
  const { setErrorMessage } = useAleiirt();
  const axiosPrivate = useAxiosPrivate(false);

  const mutation = useEiirMutation({
    mutationKey: [uploadKey],
    mutationFn: async (formData) =>
      await axiosPrivate.post(pathname, formData, {
        onUploadProgress: ({ loaded, total }) => {
          const progress = Math.round((loaded / total) * 100);
          console.log(progress);
        },
      }),
    onSuccess: (data) => callback && callback(data),
    onError: ({ message }) => setErrorMessage(message),
  });

  const layOverview = useCallback(async (files, isAtomic) => {
    const selectedFile = files[0].file;
    const formData = new FormData();

    // calculate the number of chunks and the size of each chunk
    const totalChunks = Math.ceil(selectedFile.size / BUFFER_SIZE);

    formData.append("file", selectedFile);
    formData.append("fileName", selectedFile.name);
    formData.append("totalChunks", totalChunks);
    formData.append("isatomic", isAtomic);

    mutation.mutate(formData);
    // eslint-disable-next-line
  }, []);

  const analyze = useCallback(async (files, options) => {
    const flatFiles = Object.values(files).reduce((rObj, file) => {
      if (file.isDirectory) {
        for (let f of Object.values(file.files)) rObj.push(f);
      } else rObj.push(file);

      return rObj;
    }, []);

    // create a FormData object and add file information
    const formData = new FormData();

    // set validation checks and options
    formData.append("checks", JSON.stringify(options));

    flatFiles.forEach((file, index) => {
      const selectedFile = file.file;

      // calculate the number of chunks and the size of each chunk
      const totalChunks = Math.ceil(selectedFile.size / BUFFER_SIZE);

      formData.append("files", selectedFile);
      formData.append(`files[${index}]-totalChunks`, totalChunks);

      // loop through and upload chunks
      for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
        const start = chunkNumber * BUFFER_SIZE;
        const end = Math.min(start + BUFFER_SIZE, selectedFile.size);
        const chunk = selectedFile.slice(start, end);

        formData.append(
          `files[${index}]-chunk-${chunkNumber}`,
          chunk,
          selectedFile.name
        );
      }
    });

    mutation.mutate(formData);
    // eslint-disable-next-line
  }, []);

  return { isLoading: mutation.isLoading, layOverview, analyze };
};

export default useUpload;
