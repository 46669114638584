const Radio = ({ label, onChange, ...props }) => {
  const handleChange = ({ target }) => {
    onChange && onChange(target.value);
  };

  return (
    <div className="radio-container">
      <input type="radio" {...props} onChange={handleChange} />
      <label htmlFor={props.id}>{label}</label>
    </div>
  );
};

export const RadioGroup = ({
  group,
  name,
  disabled,
  className,
  onRadioChange,
  selectedValue,
}) => {
  const inputControls = Object.values(group).map((choice) => {
    return (
      <Radio
        name={name}
        id={choice.id || choice.value}
        key={choice.value}
        checked={choice.value === selectedValue}
        disabled={disabled}
        onChange={(value) => {
          onRadioChange && onRadioChange(name, value);
        }}
        {...choice}
      />
    );
  });

  return (
    <section className={className || "pt-2 pb-3"}>{inputControls}</section>
  );
};

export default Radio;
