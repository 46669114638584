import { MdOutlineNearbyError as ErrIcon } from "react-icons/md";
import { Button, InputField, Panel, PanelBody } from "../../components";
import { required } from "../../form/validation-rules";
import { useEiirMutation } from "../../repo-query";
import { Urls } from "../../store/axios";
import {
  useAxiosPrivate,
  useEiirForm,
  useRequirements,
} from "../../store/hooks";

const initialPwds = {
  currpwd: { rules: [required("Current Password")] },
  newpwd: { rules: [required("New Password")] },
  confirmpwd: { rules: [required("Confirm Password")] },
};

export const ShowPassword = ({ onUpdate }) => {
  const axiosPrivate = useAxiosPrivate();
  const { checkList, error, onClear, onResetError, onValidate, onVerify } =
    useRequirements();
  const { register, resetForm, handleSubmit } = useEiirForm(initialPwds);

  const handleFocus = () => onResetError();

  const handleFormReset = () => {
    onClear();
    resetForm();
  };

  const mutator = useEiirMutation({
    mutationKey: ["updatePassword"],
    mutationFn: async ({ current, updated }) =>
      await axiosPrivate.patch(Urls.resetPassword, {
        current,
        updated,
        reset: true,
      }),
    onError: ({ message }) => onResetError(message),
    onSuccess: () => {
      onUpdate("Password was updated succesfully");
      handleFormReset();
    },
  });

  const onSubmit = (data) => {
    const { currpwd, newpwd, confirmpwd } = data;
    const errorMessage = onVerify(newpwd, newpwd === confirmpwd);
    if (errorMessage) {
      onResetError(errorMessage);
    } else {
      mutator.mutate({ current: currpwd, updated: newpwd });
    }
  };

  return (
    <>
      <Panel>
        <PanelBody>
          <div className="inline-flex my-3 ml-10">
            <div className="flex flex-col space-y-4 w-[300px] min-w-[300px]">
              <form
                id="resetpwd-form"
                onSubmit={handleSubmit(onSubmit, () =>
                  onResetError(
                    " The information you entered is incomplete or invalid."
                  )
                )}
                onReset={handleFormReset}
              >
                <div className="flex flex-col space-y-3">
                  <InputField
                    {...register("currpwd", { type: "password" })}
                    label="Current Password"
                    helperText="Your EIIR account"
                    onFocus={handleFocus}
                    required
                  />
                  <InputField
                    {...register("newpwd", {
                      type: "password",
                      onChange: (_, value) => onValidate(value),
                    })}
                    label="New Password"
                    helperText="Must be different than current password"
                    onFocus={handleFocus}
                    required
                  />
                  <InputField
                    {...register("confirmpwd", { type: "password" })}
                    label="Confirm Password"
                    helperText="Must match new password"
                    onFocus={handleFocus}
                    required
                  />
                </div>
                <div className="flex space-x-1 pt-3">
                  <Button
                    type="submit"
                    content="Save Changes"
                    sx={{ padding: 10 }}
                    loading={mutator.isLoading}
                  />
                  <Button type="reset" content="Reset" sx={{ padding: 10 }} />
                </div>
              </form>
            </div>

            <div className="ml-10">
              <p className="font-semibold leading-tight text-[15px]">
                Change Password & Requirements:
              </p>
              <span className="text-sm leading-tight">
                Ensure the following are met:
              </span>
              <section className="inline-block text-[15px] text-clip overflow-hidden mt-3 mr-3 space-y-0.5 max-w-[280px]">
                {checkList}

                <div
                  key="errcheck"
                  className={`flex items-center space-x-2 text-red-500 transition-all ease-in duration-150 ${
                    error ? "visible opacity-100" : "invisible opacity-0"
                  }`}
                >
                  <span>
                    <ErrIcon size={19} />
                  </span>
                  <span className="text-wrap leading-tight">{error}</span>
                </div>
              </section>
            </div>
          </div>
        </PanelBody>
      </Panel>
    </>
  );
};
