export const updaters = {
  onLoading: (oldState) => {
    return {
      ...oldState,
      isFetching: true,
      isLoading: true,
      error: undefined,
    };
  },
  onSuccess: (oldState, data) => {
    return {
      ...oldState,
      status: "success",
      lastUpdated: Date.now(),
      data,
    };
  },
  onError: (oldState, error) => ({
    ...oldState,
    status: "error",
    error,
  }),
  onSettled: (oldState) => {
    return {
      ...oldState,
      isFetching: false,
      isLoading: false,
    };
  },
};

const respHandler = async (query, fn, data, options) => {
  const { onSuccess, onError } = options;
  const resp = await fn(data);

  if (resp.status === 200 || resp.status === 201) {
    query.setState((oldState) => updaters.onSuccess(oldState, resp.data));
    onSuccess && onSuccess(resp.data, data);
  } else if (resp.status === "success") {
    onSuccess && onSuccess(resp.data, data);
  } else if (resp.status === "error") {
    query.setState((oldState) => updaters.onError(oldState, resp.message));
    onError && onError({ status: "error", message: resp.message }, data);
  }
};

const errorHandler = (query, onError, response) => {
  const { detail } = response.data;
  query.setState((oldState) => updaters.onError(oldState, detail));
  onError && onError({ status: response.status, message: detail });
};

const runQuery = async (query, fn, data, options = {}) => {
  query.setState(updaters.onLoading);
  query.mounted = true;

  try {
    await respHandler(query, fn, data, options);
  } catch (error) {
    let response = error.response;
    if (!response) response = { data: { detail: error.toString() } };
    errorHandler(query, options.onError, response);
  } finally {
    query.setState(updaters.onSettled);
    options.onSettled && options.onSettled();
    query.mounted = false;
  }
};

export function createQuery(queryKey, queryFn) {
  const query = {
    queryKey,
    queryHash: JSON.stringify(queryKey),
    mounted: false,
    subscribers: [],
    state: {
      status: "pending",
      isFetching: true,
      isLoading: false,
      data: undefined,
      error: undefined,
      lastUpdated: undefined,
    },
    setState: (updater) => {
      query.state = updater(query.state);
      query.subscribers.forEach((s) => s.notify());
    },
    unsubscribe: (subscriber) => {
      query.subscribers.filter((s) => s !== subscriber);
    },
    subscribe: (subscriber) => {
      if (query.subscribers.filter((s) => s === subscriber).length === 0) {
        query.subscribers.push(subscriber);
        return () => query.unsubscribe(subscriber);
      }
    },
    fetchQuery: async () => {
      if (!query.mounted) await runQuery(query, queryFn);
    },
    mutateQuery: async (data, options) => {
      if (!query.mounted) await runQuery(query, queryFn, data, options);
    },
  };

  return query;
}
