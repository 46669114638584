import { createContext } from "react";
import DataExport from "../../pages/reports/DataExport";

const ReportContext = createContext();

function getAdditionalData(dest) {
  let additional;
  switch (dest.connector_type) {
    case "Channel Writer":
      additional = [`Name: ${dest.channe_name} (${dest.uuid})`];
      break;
    case "Database Writer":
    case "HTTP Sender":
      additional = [`Url ${dest.url}`];
      break;
    case "Document Writer":
      additional = [`Directory ${dest.directory}`];
      break;
    case "File Writer":
    case "SMTP Sender":
      additional = [`Directory: ${dest.host}`];
      break;
    case "JavaScript Writer":
      additional = [dest.goal];
      break;
    case "JMS Sender":
      additional = [
        `Client Id: ${dest.client_id}, Destination: ${dest.destination}`,
      ];
      break;
    case "TCP Sender":
      additional = [`Port ${dest.port}`];
      break;
    default:
      additional = [];
  }

  return additional.join(", ");
}

/**
 * Convert the channels data to CSV-ready formats
 */
function createCSV(channels) {
  const headers =
      "SEQ,Source Connector,Server,Port,Channel Name,Channel UUID,Channel Group,Description,Data Format,Active",
    secondaryHeaders =
      ",Destination Id,Connector Type,Connector,Active,Additional Data";
  const dataCsv = [headers];

  channels.forEach((channel, index) => {
    const row = [
      index + 1,
      channel.source_connector,
      channel.mirthserver.name,
      channel.port,
      channel.name,
      channel.uuid,
      channel.channelgroup?.name,
      `"${channel.description}"`.replace(/\n+$/g, ""),
      channel.dataformat.name,
      channel.is_enabled ? "Yes" : "--",
    ];
    dataCsv.push(row.join(","));

    // Add secondary header for channel destinations
    // using first column as indentation
    dataCsv.push(secondaryHeaders);
    channel.destinations?.forEach((dest, index) => {
      const secondaryRow = [
        "",
        dest.destination_id,
        dest.connector_type,
        dest.name,
        dest.is_enabled ? "Yes" : "--",
        getAdditionalData(dest),
      ];
      dataCsv.push(secondaryRow.join(","));
    });

    // Add an optional new line for readability
    dataCsv.push("");
  });

  return dataCsv;
}

export const ReportProvider = ({ children }) => {
  const controller = new DataExport();

  return (
    <ReportContext.Provider
      value={{
        xportControl: controller,
        channelsToCSV: createCSV,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export default ReportContext;
