import { v4 as uuidv4 } from "uuid";

export function createQueryObserver(
  client,
  { queryKey, queryFn, staleTime = 0, enabled = true }
) {
  let query = client.getQuery({ queryKey, queryFn });

  const __searchQuery = (key) =>
    client.getQuery({
      queryKey: key,
      create: false,
    });

  const observer = {
    id: uuidv4(),
    notify: () => {},
    isEnabled: () => {
      return (
        !query.state.lastUpdated ||
        Date.now() - query.state.lastUpdated > staleTime
      );
    },
    subscribe: (rerender) => {
      const unsubscribe = query.subscribe(observer);
      observer.notify = rerender;
      if (enabled && observer.isEnabled()) query.fetchQuery();
      return unsubscribe;
    },
    refetch: async (refetchKey) => {
      if (refetchKey) {
        const refetchQuery = __searchQuery(refetchKey);
        refetchQuery.subscribe(observer);
        if (observer.isEnabled()) refetchQuery.fetchQuery();
      }
    },
    getQueryState: (queryKey) => {
      const query = __searchQuery(queryKey);
      return query.state;
    },
  };

  return observer;
}
