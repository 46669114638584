import { useState } from "react";
import { MdDone } from "react-icons/md";
import { Button, Checkbox, IconButton } from "../../components";

function init(arr) {
  return arr?.map((_) => false);
}

export const ReportConnectorForm = ({ connectors, onAccept, onClose }) => {
  const [values, setValues] = useState(init(connectors));

  const handleChange = (index) =>
    setValues((prev) => {
      const updated = [...prev];
      updated[index] = !updated[index];
      return updated;
    });

  const handleAccept = () => {
    onAccept(values, () => setValues((prev) => init(prev)));
  };

  return (
    values?.length > 0 && (
      <section>
        <div className="py-2 px-4 text-sm bg-white">
          <div className="font-semibold text-gray-700 py-1 pb-2 tracking-tight">
            Source Connectors
          </div>
          {connectors.map((source, i) => (
            <Checkbox
              key={i}
              id={`check-${source.connector}`}
              label={source.alias}
              onChange={() => handleChange(i)}
              checked={values[i]}
            />
          ))}
        </div>
        <div className="flex items-center justify-end p-3 space-x-3 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
          <IconButton id="close-btn" label="Close" onClick={onClose} />
          <Button id="apply-btn" onClick={handleAccept}>
            <div className="flex-ij text-xs">
              <MdDone size={18} />
              <span>Accept</span>
            </div>
          </Button>
        </div>
      </section>
    )
  );
};
