import { useCallback, useState } from "react";
import {
  FloatingInput,
  Panel,
  PanelBody,
  ToggleSwitch,
} from "../../components";
import { useAxiosPrivate, useTimeout, useToast } from "../../store/hooks";
import { useEiirMutation } from "../../repo-query";

export const ShowProfile = ({ self, account, onUpdate }) => {
  const axiosPrivate = useAxiosPrivate();
  const { warn, clear, element: toastEl } = useToast();
  const [updates, setUpdates] = useState({
    active: account.status === "A",
    locked: account.accountStatus !== "Unlocked",
    phone: account.phone,
  });

  const [delay, setDelay] = useState(0);

  const mutator = useEiirMutation({
    mutationKey: ["users", { publicId: account.publicId, type: "phone" }],
    mutationFn: async (phone) =>
      await axiosPrivate.patch("/users/profile/phone", { phone }),
    onSuccess: ({ message }) => onUpdate(message),
    onError: (error) => console.log(error),
  });

  const handleClear = useCallback(() => {
    clear();
    setDelay(0);
  }, [clear]);

  const handleCancel = useCallback(() => {
    setDelay(2000);
    warn("Phone number update canceled");
  }, [warn]);

  const handleUpdate = useCallback(
    (message) => {
      if (message !== updates.error) {
        setUpdates((curr) => ({ ...curr, error: message }));
        warn(message);
      }
    },
    [updates.error, warn]
  );

  const handleUpdatePhone = useCallback(
    async (phone, isSame) => {
      if (isSame) return;
      const phoneValid =
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(phone);
      if (!phoneValid) handleUpdate("Improper phone number!");
      else mutator.mutate(phone);
    }, // eslint-disable-next-line
    [handleUpdate]
  );

  useTimeout(handleClear, delay);

  return (
    <Panel>
      <PanelBody>
        <div>
          <div className="userprofile">
            <div>
              <span className="header">Public Id:</span>
              <span>{account.publicId}</span>
            </div>
            <div>
              <span className="header">Name Display:</span>
              <span>{account.name.display}</span>
            </div>
            <div>
              <span className="header">User Role:</span>
              <span>{account.role.name}</span>
            </div>
            <div>
              <span className="header">Access Type:</span>
              <span>eiir authentication</span>
            </div>
            <div>
              <span className="header">Created On:</span>
              <span>{account.createdOn}</span>
            </div>
            <div>
              <span className="header">Department:</span>
              <span>{account.department}</span>
            </div>
            <div>
              <span className="header">Last Modified:</span>
              <span>{account.updatedOn}</span>
            </div>
            <div className="flex-i">
              <span className={`header${self ? " mb-[16px]" : ""}`}>
                Phone:
              </span>
              <div className="flex mt-2 items-start">
                {self ? (
                  <>
                    <FloatingInput
                      helperText="Numbers only, e.g.: 3051234567"
                      defaultValue={updates.phone}
                      width={190}
                      label="Phone"
                      id="user-phone"
                      onChange={() => handleUpdate(null)}
                      onPopOver={handleUpdatePhone}
                      onCancel={handleCancel}
                    />
                    {toastEl}
                  </>
                ) : (
                  <span>{updates.phone}</span>
                )}
              </div>
            </div>
            <div className="flex">
              <span className="header">Active:</span>
              <div className="mt-2">
                <ToggleSwitch
                  disabled
                  id="active-switch"
                  allowed={updates.active}
                />
              </div>
            </div>
            <div className="flex">
              <span className="header">Account Locked:</span>
              <div className="mt-2">
                <ToggleSwitch
                  disabled
                  id="lock-switch"
                  allowed={updates.locked}
                />
              </div>
            </div>
          </div>
        </div>
      </PanelBody>
    </Panel>
  );
};
