import { memo, useEffect, useMemo, useState } from "react";
import { MdNavigateNext as NextIcon } from "react-icons/md";
import { RiEditBoxLine as EditIcon } from "react-icons/ri";
import { Button, Divider, Radio } from "../../../components";
import { useEiirQuery } from "../../../repo-query";
import { Urls } from "../../../store/axios";
import { useAlert, useAuth, useUserRequest } from "../../../store/hooks";
import { PolicySection, initToggles } from "./PolicySection";

const errorMessage =
  "User has not been assigned a security role. Please assign one!";

export const RoleCreate = memo(() => {
  const [userRole, setUserRole] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { repoAlert, setErrorMessage } = useAlert("rolecreate-alert");
  const { userRequest, apiClient, onNext, onPrevious } = useUserRequest();
  const { isAdmin } = useAuth();

  const onEditing = () => setIsEditMode(true);

  const onStopEditing = (toggles) => {
    console.log(toggles);
    userRequest.permissions = toggles;
    setIsEditMode(false);
  };

  const onRadioChange = (value) => {
    userRequest.setRole(value);
    setUserRole(value);
  };

  const descriptions = useMemo(
    () => [
      {
        label: "SuperUser",
        details: "No Administrator access.",
      },
      {
        label: "Admin",
        details:
          "Allows users to make changes. " +
          "Granted rights allow to create, delete, and modify settings.",
      },
      {
        label: "SuperAdmin",
        details:
          "Has access to the full set of admin permissions as well as " +
          "permissions to set global settings.",
      },
    ],
    []
  );

  const assigner = useMemo(() => {
    const url = `${Urls.actions}/${userRole}`;
    return {
      url,
      queryKey: ["userRole", { role: userRole }],
      queryFn: async () => await apiClient(url),
      enabled: false,
      slateTime: 30,
    }; // eslint-disable-next-line
  }, [userRole]);

  const { data, isLoading, refetch } = useEiirQuery(assigner);

  useEffect(() => {
    if (!userRole) return;
    refetch();
    // eslint-disable-next-line
  }, [userRole]);

  const handleRoleSubmit = async (event) => {
    event.preventDefault();

    if (userRole !== null) {
      if (!userRequest.hasPolicies())
        userRequest.permissions = initToggles(data);
      onNext();
    } else setErrorMessage(errorMessage);
  };

  return (
    <>
      {repoAlert}
      {isEditMode ? (
        <PolicySection
          isSuperUser={userRole === "SuperUser"}
          role={userRole}
          categories={data}
          onBack={onStopEditing}
        />
      ) : (
        <form onSubmit={handleRoleSubmit} className="drawer-section">
          <div className="userrole">
            <p className="title">Choose the role to assign for this user!</p>
            <div className="roles">
              {descriptions.map(({ label, details }) => {
                const isCurrRole = userRole === label;

                return (
                  <div key={label} className="role">
                    <div className="control">
                      {
                        <Radio
                          label={label}
                          value={label}
                          name="role"
                          checked={isCurrRole}
                          disabled={!isAdmin}
                          onChange={onRadioChange}
                        />
                      }
                    </div>
                    <div className="w-2/3">
                      <div className={`details ${isCurrRole ? "active" : ""}`}>
                        <p className="font-semibold">{label}</p>
                        <p className="py-1 leading-tight">{details}</p>
                      </div>
                      {isCurrRole && (
                        <div className="editbtn">
                          <div>
                            <Button
                              outline
                              onClick={onEditing}
                              loading={isLoading}
                            >
                              <div className="edit">
                                <span>Edit Policies</span>
                                <EditIcon size={18} />
                              </div>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flexj space-x-2 mb-5">
            <Button onClick={onPrevious}>
              <div className="stepbtn">
                <span className="-scale-x-100">
                  <NextIcon size={20} />
                </span>
                <span className="label pr-1">PREVIOUS</span>
              </div>
            </Button>
            <Divider />
            <Button type="submit">
              <div className="stepbtn">
                <span className="label pl-1.5">NEXT</span>
                <span>
                  <NextIcon size={20} />
                </span>
              </div>
            </Button>
          </div>
        </form>
      )}
    </>
  );
});
