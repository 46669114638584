import { useState } from "react";
import {
  Button,
  Dropdown,
  InputField,
  Panel,
  PanelBody,
  RadioGroup,
} from "../../components";
import {
  useAleiirt,
  useAxiosPrivate,
  useEiirForm,
  useReport,
} from "../../store/hooks";
import { required } from "../../form/validation-rules";
import { useEiirMutation } from "../../repo-query";
import { Urls } from "../../store/axios";

const customExtRule = {
  name: "Custom",
  message: "Invalid or incorrect filename extension type",
  validate: (name) => /^[\w\s-]+\.csv/.test(name),
};

function init() {
  return {
    report: {
      rules: [required("Report Name"), customExtRule],
    },
    inventorygrp: {
      group: [
        { label: "Inventory Summary", value: "summary" },
        { label: "Managing Organizations", value: "orgs" },
      ],
    },
  };
}

const statusOptions = ["All", "Active", "Inactive"];

export const InventoryReports = () => {
  const [reportDisabled, setReportDisabled] = useState(true);
  const [statusDisabled, setStatusDisabled] = useState(true);
  const { setErrorMessage } = useAleiirt();
  const { xportControl } = useReport();
  const axiosPrivate = useAxiosPrivate();

  const { register, resetForm, handleSubmit } = useEiirForm(init());

  const reportConf = {
    mutationKey: ["inventory-rpts"],
    mutationFn: async (data) =>
      await axiosPrivate.post(Urls.reports.feeds, data),
    onSuccess: (data, variables) => {
      Promise.resolve(data)
        .then((data) => {
          const { filename, selection } = variables;
          if (selection === "summary") xportControl.exportCSV(data, filename);
          else xportControl.downloadFile(data, filename, "text/csv");
        })
        .finally(() => {
          setStatusDisabled(true);
          setReportDisabled(true);
          resetForm();
        });
    },
    onError: ({ message }) => setErrorMessage(message),
  };

  const mutation = useEiirMutation(reportConf);

  const onSubmit = (data) => {
    const { inventorygrp, report, rptstatus } = data;
    mutation.mutate({
      filename: `${rptstatus || "all"}-${report}`.toLowerCase(),
      selection: inventorygrp,
    });
  };

  return (
    <form className="h-full w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="stepper">
        <ul>
          {["first", "between", "last"].map((name, i) => (
            <li key={name}>
              <div className={name}>
                <span>{i + 1}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex space-x-4 h-full">
        <div className="w-[30%]">
          <Panel>
            <PanelBody>
              <p className="font-semibold mb-2 pb-2 text-[15px]">
                Select Report
              </p>
              <RadioGroup
                {...register("inventorygrp", {
                  type: "group",
                  onChange: (value) => {
                    setReportDisabled(false);
                    setStatusDisabled(value === "orgs");
                  },
                })}
                className="flex flex-col space-y-1"
              />
            </PanelBody>
          </Panel>
        </div>

        <Panel className="w-[50%]">
          <PanelBody>
            <p className="font-semibold mb-2 pb-2 text-[15px]">
              Customize Your Report
            </p>
            <div className="space-y-3">
              <InputField
                {...register("report")}
                label="Report Name"
                helperText="Example: inventory-summary.csv"
              />
              <div
                className={`ml-1.5 ${
                  statusDisabled ? "hidden-scale" : "opacity-scale"
                }`}
              >
                <Dropdown
                  {...register("rptstatus", { type: "select" })}
                  helperText="Active Status"
                  sourceItems={statusOptions}
                />
              </div>
            </div>
          </PanelBody>
        </Panel>

        <div className="max-w-[20%] p-4">
          <p className="font-semibold text-[15px] mb-1">Generate Report</p>
          <Button
            sx={{ width: 176 }}
            type="submit"
            loading={mutation.isLoading}
            disabled={reportDisabled}
          >
            <span>Download Report</span>
          </Button>
        </div>
      </div>
    </form>
  );
};
