import { MdOutlineHighlightOff as Icon } from "react-icons/md";
import { useAuth } from "../store/hooks";

const Restrict = ({ allowedAction, hidden, children }) => {
  const { auth } = useAuth();
  const { permissions } = auth || {};

  let allowed;
  if (!permissions) allowed = false;
  else
    allowed =
      allowedAction in permissions && permissions[allowedAction].allowed;

  return allowed ? (
    children
  ) : hidden ? null : (
    <div className="restrict">
      <Icon size={24} color="darkred" />
      <span className="ml-2">
        You don't have permission to perform: {allowedAction}
      </span>
    </div>
  );
};

export default Restrict;
