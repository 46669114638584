import { useRef, useState } from "react";
import {
  BsWindows as EiirIcon,
  BsSignpost2 as LocationIcon,
  BsSliders2 as SliderIcon,
} from "react-icons/bs";
import { FiSettings as SettingsIcon } from "react-icons/fi";
import { GoGitMerge as ServiceIcon } from "react-icons/go";
import {
  MdSpellcheck as AnalysisIcon,
  MdToll as ChannelsIcon,
  MdOutlineDashboard as DashboardIcon,
  MdOutlineNat as FeedIcon,
} from "react-icons/md";
import { PiChartPieSlice as ReportIcon } from "react-icons/pi";
import { TbUser as UserIcon } from "react-icons/tb";
import { SimpleButton } from "../../components";
import { useAuth, useNavigateHistory } from "../../store/hooks";
import { generatePath } from "react-router-dom";
import { Urls } from "../../store/axios";

const sidebarItems = {
  channels: { label: "Channels", url: "/channels", Icon: ChannelsIcon },
  feeds: { label: "Mirth Feeds", url: "/feeds", Icon: FeedIcon },
  interfaces: { label: "Interfaces", url: "/interfaces", Icon: ServiceIcon },
  settings: { label: "Settings", url: "/settings", Icon: SliderIcon },
  facilities: { label: "Facilities", url: "/facilities", Icon: LocationIcon },
  analysis: { label: "Analysis", url: "/analysis", Icon: AnalysisIcon },
  reports: { label: "Reports", url: "/reports", Icon: ReportIcon },
};

const Sidebar = () => {
  const { goTo } = useNavigateHistory();
  const { auth } = useAuth();
  const account = useRef(auth.email ? auth.email.split("@")[0] : null);
  const [simpleButtons] = useState(() => {
    return Object.entries(sidebarItems).reduce((obj, [key, item]) => {
      const btn = (
        <SimpleButton
          key={key}
          className="sblink"
          onClick={() => goTo(`/repo${item.url}`)}
        >
          <item.Icon size={22} />
          <p>{item.label}</p>
        </SimpleButton>
      );
      obj[key] = btn;
      return obj;
    }, {});
  });

  return (
    // <nav className="fixed left-0 h-full z-[100] text-[15px]">
    <nav className="sbar">
      {/* <div id="sidebar-left" className="sbar"> */}
      <div className="hidden xl:flex justify-start p-6 items-center space-x-3">
        <EiirIcon size={34} fill="white" />
        <p className="sb-title">eiir Interfaces</p>
      </div>
      <div className="sb-dashboard">
        <SimpleButton
          className="sbmain"
          onClick={() => goTo("/repo/dashboard")}
        >
          <DashboardIcon size={22} id="sb-icon" />
          <p>Dashboard</p>
        </SimpleButton>
        <SimpleButton
          className="sbmain"
          onClick={() => goTo("/repo/settings/users")}
        >
          <UserIcon size={22} id="sb-icon" />
          <p>Users</p>
        </SimpleButton>
      </div>
      <div className="sb-overview">
        <p className="sb-header">Repository</p>
        <div id="repository-menu" className="overview-menu">
          {simpleButtons.channels}
          {simpleButtons.feeds}
          {simpleButtons.interfaces}
          {simpleButtons.settings}
        </div>
      </div>
      <div className="sb-overview">
        <p className="sb-header">Vendors</p>
        <div id="vendor-menu" className="overview-menu">
          {simpleButtons.facilities}
        </div>
      </div>
      <div className="sb-overview">
        <div className="sb-header">
          <p className="">Services</p>
        </div>
        <div id="services-menu" className="overview-menu">
          {simpleButtons.analysis}
          {simpleButtons.reports}
        </div>
      </div>
      <div className="sb-footer">
        <span />
        <div className="sb-account">
          <div>
            <p className="text-sm leading-5 text-white">{auth.display}</p>
            <p className="text-xs leading-3 text-gray-300">{account.current}</p>
          </div>
          <SettingsIcon
            size={20}
            id="sb-icon"
            onClick={() =>
              goTo(generatePath(Urls.profile.view, { id: auth.publicId }))
            }
          />
        </div>
      </div>
      {/* </div> */}
    </nav>
  );
};

export default Sidebar;
