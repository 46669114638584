import { MdOutlineBubbleChart as ReportIcon } from "react-icons/md";
import { Panel, PanelBody, PanelHeader } from "../../components";
import { useAlert } from "../../store/hooks";
import { ChannelsReports } from "./ChannelsReports";
import { InventoryReports } from "./InventoryReports";
import { UserReport } from "./UserReport";

const Reports = () => {
  const { repoAlert, setAlertMessage } = useAlert("rpt-alert", true);

  return (
    <>
      {repoAlert}
      <Panel>
        <PanelHeader
          PanelIcon={ReportIcon}
          title="Reports Center"
          subtitle="Exports"
        />
        <PanelBody>
          <div className="reports">
            <UserReport />
            <ChannelsReports onAlert={(err) => setAlertMessage(err, true)} />
          </div>
        </PanelBody>
      </Panel>
      <InventoryReports />
    </>
  );
};

export default Reports;
