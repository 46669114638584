function createFilter(rerender) {
  const filterObj = {
    state: {
      page: 1,
      pageSize: 24,
    },
    notify: () => {
      rerender();
    },
    setState: (updater) => {
      filterObj.state = updater(filterObj.state);
      filterObj.notify();
    },
  };

  return filterObj;
}

export class MediatorClient {
  constructor(rerender) {
    this.filterCollegues = [];
    this.filter = createFilter(rerender);
  }
  register = (collegue) => {
    const isNew =
      this.filterCollegues.filter((c) => c === collegue).length === 0;
    if (isNew) this.filterCollegues.push(collegue);
    return isNew;
  };
  deregister = () => (this.filterCollegues.length = 0);
  notify = (updater) => this.filter.setState(updater);
  getState = () => this.filter.state;
  render = () => {
    return this.filterCollegues.map(({ id, createElement }) =>
      createElement(id)
    );
  };
}
