// Regular expressions
const emailExp = /^[A-Z_%+-]+\.+[A-Z0-9_%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const portExp = /^(\d{4,5})$/i;
const validExpr = new RegExp("(?=.*[a-zA-Z0-9!@#$%^&*])");

function createValidationRule(ruleName, error, fnValidate, description = "") {
  return {
    name: ruleName,
    description,
    message: error,
    validate: fnValidate,
  };
}

export const lowerCase = (inputName = "") => {
  return createValidationRule(
    "lowerCase",
    `${inputName} must at least have one lowercase character`.trim(),
    (inputValue) => new RegExp("(?=.*[a-z])").test(inputValue),
    "At least one lowercase character"
  );
};

export const upperCase = (inputName = "") => {
  return createValidationRule(
    "upperCase",
    `${inputName} must at least have one uppercase character`.trim(),
    (inputValue) => new RegExp("(?=.*[A-Z])").test(inputValue),
    "At least one uppercase character"
  );
};

export const specialChar = (inputName = "") => {
  return createValidationRule(
    "specialChar",
    `${inputName} must at least have one special character`.trim(),
    (inputValue) => new RegExp("(?=.*[!@#$%^&*])").test(inputValue),
    "At least one special character !@#$%^&*"
  );
};

export const number = (inputName = "") => {
  return createValidationRule(
    "number",
    `${inputName} must have at least one digit`.trim(),
    (inputValue) => new RegExp("(?=.*[0-9])").test(inputValue),
    "At least one number"
  );
};

export const required = (inputName) => {
  return createValidationRule(
    "required",
    `${inputName} is required`,
    (inputValue) => inputValue?.length > 0
  );
};

export const format = (inputName) => {
  return createValidationRule(
    "format",
    `${inputName} is not in the correct first.last format`,
    (inputValue) => inputValue?.includes(".")
  );
};

export const minLength = (minCharacters, allowEmpty = true, inputName = "") => {
  return createValidationRule(
    "minLength",
    `${inputName} should contains at least ${minCharacters} characters`,
    (inputValue) => {
      const chars = inputValue.length;
      return chars === 0 && allowEmpty
        ? true
        : new RegExp(`(?=.{${minCharacters},})`).test(inputValue);
    },
    "Minimum six characters"
  );
};

export const phone = (inputName) => {
  return createValidationRule(
    "phone",
    `${inputName} is not in a valid format`,
    (inputValue) => (inputValue ? /^(\d{9,16})$/i.test(inputValue) : true)
  );
};

export const ipCheck = () => {
  return createValidationRule(
    "ipaddress",
    `Please enter a valid IP address`,
    (inputValue) => {
      if (!inputValue) return false;

      const parts = inputValue.split(".");
      if (parts.length !== 4) return false;

      return parts.every((part) => /^-?\d+$/.test(part));
    }
  );
};

export const isPort = () => {
  return createValidationRule(
    "port",
    `Incorrect or out of range Port`,
    (inputValue) => (inputValue ? portExp.test(inputValue) : true),
    true
  );
};

export const isValidEmail = (inputName) => {
  return createValidationRule(
    "isEmail",
    `${inputName} must be a valid Envision email`,
    (email) => {
      // /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
      if (!email?.endsWith("@envisionhealth.com")) return false;
      return emailExp.test(email);
    }
  );
};

export const invalidateChar = (text) => {
  for (let char of text || "") if (!validExpr.test(char)) return false;
  return true;
};

export const validationHandler = (rules, value) => {
  let error;
  let valid = true;

  for (let rule of rules) {
    if (!rule.validate(value)) {
      valid = false;
      error = rule.message;
      break;
    }
  }

  return { valid, error };
};
