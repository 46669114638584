class FormatUtils {
  static formatPercentage({
    value,
    decimals = 2,
    sign = false,
    padStart = false,
  }) {
    let percent = (value * 100).toFixed(decimals);
    if (padStart) percent = percent.toString().padStart(5, "0");
    return sign ? `${percent}%` : percent;
  }
}

export default FormatUtils;
