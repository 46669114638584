import { useMemo } from "react";

function range(start, end) {
  const length = end - start + 1;
  /*
    Create an array range and set the elements from
    start page to end page
  */
  return Array.from({ length }, (_, i) => i + start);
}

//  siblingCount (optional): the minimum number of page buttons to be shown
//  on each side of the current button
const usePagination = (totalPageCount, currPage, siblingCount = 1) => {
  const navigatorRange = useMemo(() => {
    /*
      Total page count is determined as
      current page, first, last, current dots, and sibling (adjacent) page
      */
    const totalLinks = siblingCount + 3;

    /*
      Case 1:
      If the number of pages is less than the page numbers we need to show
      */
    if (totalLinks >= totalPageCount - 1) {
      return range(1, totalPageCount);
    }

    /*
      Get the left and right sibling index
      while making sure they fall within the [1...totalPageCount] range
      */
    const leftSibling = Math.max(currPage - siblingCount, 1);
    const rightSibling = Math.min(currPage + siblingCount, totalPageCount);

    /*
      We do not show dots just when there is just one page number to be inserted
      between the extremes of sibling and the page limits i.e 1 and totalPageCount.
      Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowRight = rightSibling < totalPageCount - 2;
    const shouldShowLeft = leftSibling > 2;
    /*
      Case 2:
      Showing right dots
    */
    if (!shouldShowLeft && shouldShowRight) {
      const leftPages = 2 + 2 * siblingCount;
      const leftRange = range(1, leftPages);
      return [...leftRange, null, totalPageCount];
    }

    /*
      Case 3
      Show left dots but no right dots
    */
    if (shouldShowLeft && !shouldShowRight) {
      const rightPages = 2 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightPages + 1, totalPageCount);
      return [1, null, ...rightRange];
    }

    /*
      Case 4
      Both left and right dots to show
    */
    if (shouldShowLeft && shouldShowRight) {
      let middleRange = range(leftSibling, rightSibling);
      return [1, null, ...middleRange, null, totalPageCount];
    }
  }, [totalPageCount, currPage, siblingCount]);

  return navigatorRange;
};

export default usePagination;
