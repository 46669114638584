import { useEffect, useRef } from "react";
import { useEiirQueryClient } from ".";
import { useForceUpdate } from "../store/hooks";
import { createQueryObserver } from "./createQueryObserver";

const useEiirQuery = ({ queryKey, queryFn, enabled, staleTime }) => {
  const { forceUpdate } = useForceUpdate();
  const client = useEiirQueryClient();
  const observer = useRef(
    createQueryObserver(client, {
      queryKey,
      queryFn,
      staleTime,
      enabled,
    })
  );

  useEffect(() => {
    return observer.current.subscribe(forceUpdate);
  }, [forceUpdate]);

  return {
    ...observer.current.getQueryState(queryKey),
    refetch: () => observer.current.refetch(queryKey),
  };
};

export default useEiirQuery;
