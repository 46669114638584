import { useRef, useState } from "react";

const textInputs = ["text", "password", "phone"];

function validate(value, rules) {
  for (let rule of rules || []) {
    if (!rule.validate(value)) return rule.message;
  }
  return;
}

function anyTrue(arr) {
  return arr.some((item) => item);
}

const omit = (obj, key) => delete obj[key];

const useEiirForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const touched = useRef(false);

  const resetForm = () => {
    touched.current = false;
    setValues(initialValues);
    setErrors({});
  };

  const isFormValid = () => {
    if (touched.current) {
      return !anyTrue(Object.values(errors));
    } else {
      touched.current = true;
      const errObj = {};

      for (let [name, { value, rules }] of Object.entries(values)) {
        const error = validate(value, rules);
        if (error) errObj[name] = error;
      }

      if (anyTrue(Object.values(errObj))) {
        setErrors(errObj);
        return false;
      }
      return true;
    }
  };

  const validateInput = (name, onBlur) => {
    return () => {
      const { value, rules } = values[name];
      const error = validate(value, rules);

      if (error) {
        onBlur && onBlur(name, value);
        setErrors((curr) => ({ ...curr, [name]: error }));
      } else if (errors[name]) {
        const newObj = { ...errors };
        omit(newObj, name);
        setErrors(newObj);
      }
    };
  };

  const onInputChange = (onChange) => {
    return (event) => {
      const { name, value, checked, type } = event.target;
      const newObj = { ...values[name] };

      touched.current = true;
      newObj.value = type === "checkbox" ? checked : value;

      onChange && onChange(name, value);
      setValues((prev) => ({
        ...prev,
        [name]: newObj,
      }));
    };
  };

  const onSelectionChange = (name, value) => {
    const newObj = { ...values[name] };

    newObj.value = value;
    touched.current = true;

    setValues((prev) => ({
      ...prev,
      [name]: newObj,
    }));
  };

  const onRadioChange = (onChange) => {
    return (name, value) => {
      const newObj = { ...values[name] };
      newObj.value = value;

      onChange && onChange(value);
      setValues((prev) => ({
        ...prev,
        [name]: newObj,
      }));
    };
  };

  const getData = () => {
    return Object.entries(values).reduce((newObj, [name, { value }]) => {
      newObj[name] = value;
      return newObj;
    }, {});
  };

  const register = (name, config = {}) => {
    const inputOpt = values[name] || {};
    const inputType = config.type || "text";

    const payload = {
      name,
      required: !inputOpt.optional,
      type: inputType,
      value: inputOpt.value || "",
      errorMessage: errors[name],
    };

    if (!inputType || textInputs.includes(inputType)) {
      payload.onChange = onInputChange(config.onChange);
      payload.onBlur = validateInput(name, config.onBlur);
    } else if (inputType === "checkbox") {
      payload.checked = values[name];
    } else if (inputType === "select") {
      payload.onChange = onSelectionChange;
      payload.id = name;
    } else if (inputType === "group") {
      payload.group = inputOpt.group;
      payload.onRadioChange = onRadioChange(config.onChange);
      payload.selectedValue = values[name].value;
    }

    return payload;
  };

  const handleSubmit = (fnSubmit, fnOnValidErr) => {
    return async (event) => {
      event.preventDefault();
      if (isFormValid()) fnSubmit(getData());
      else fnOnValidErr && fnOnValidErr();
    };
  };

  return { values, register, resetForm, handleSubmit };
};

export default useEiirForm;
