import { createStyledLabel } from "../../store/hooks/use-dagre";

const position = { x: 0, y: 0 };

export function channelNodes(element) {
  const nodes = [];
  const edges = [];

  const originNode = {
    id: "origin",
    type: "origin",
    data: { label: element.name },
    style: {
      width: "auto",
    },
    position: { x: 100, y: 0 },
  };

  element.destinations.forEach((dest, i) => {
    const [label, style, animated] = createStyledLabel(dest.name, "auto");
    const newNode = {
      id: `${i}`,
      type: "output",
      data: { label },
      style,
      position,
    };
    const newEdge = {
      id: `el${originNode.id}-${newNode.id}`,
      source: originNode.id,
      target: newNode.id,
      edgeType: "step",
      animated,
    };

    nodes.push(newNode);
    edges.push(newEdge);
  });

  nodes.sort((a, b) => {
    let la = a.data.label;
    let lb = b.data.label;
    return la.length - lb.length;
  });

  return [[originNode, ...nodes], edges];
}
