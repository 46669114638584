import { useCallback } from "react";
import { delayEvent } from "./use-delay";

/**
 * fn: Retry with exponential backoff.
 * Allows the repeatedly call a specified callback at a defined interval
 * until the callback returns a truthy value
 * or the execution reaches a maximum allowed attempt,
 * indicating a successfuly resolution.
 *
 * @param {*} predicate
 * @param {*} maxAttempts
 * @param {*} baseDelayMs
 * @returns
 */
const useCallWithRetry = ({
  predicate,
  onComplete,
  onSettled,
  maxAttempts = 4,
  baseDelayMs = 5000,
}) => {
  let attempt = 1;

  // wraps the operation and handles retries
  const execute = useCallback(
    (args) => {
      const ms = baseDelayMs * 2 ** attempt++;

      delayEvent(ms).then(() => {
        predicate(args)
          .then(async (valid) => {
            if (valid) {
              onComplete();
              onSettled && onSettled();
            } else if (attempt === maxAttempts) {
              console.log("max attemps reached");
              onSettled && onSettled();
            } else {
              void execute(args);
            }
          })
          .catch(() => onSettled && onSettled());
      });
    },
    [attempt, baseDelayMs, maxAttempts, predicate, onComplete, onSettled]
  );

  return { withRetry: execute };
};

export default useCallWithRetry;
