import { useState } from "react";
import { Dropdown, InputField } from "..";
import { SubmitButton } from "./SubmitButton";

const initialState = { operator: "", value: "" };

const operators = ["equals", "contains", "starts with", "ends with"];

export function OpFilter({ id, field = "term", label, onApply }) {
  const [sel, setSelection] = useState(initialState);

  const handleSelection = (name, value) => {
    setSelection((cur) => ({ ...cur, [name]: value }));
  };

  const handleSubmit = async () => {
    const context = {
      value: sel.value,
      fnVoid: (oldState) => {
        const { filter, ...rest } = oldState;
        delete filter[field];
        return { ...rest, filter };
      },
      updater: (oldState) => {
        return {
          ...oldState,
          filter: {
            ...oldState.filter,
            [field]: { operator: sel.operator, value: sel.value },
          },
        };
      },
    };
    setSelection(initialState);
    return context;
  };

  console.log("Op renders");

  return (
    <form
      id={`${id}-form`}
      className="w-[300px] sdrop"
      onSubmit={onApply(handleSubmit)}
    >
      <p className="font-semibold">Filter by {label?.toLowerCase()}</p>
      <p className="text-neutral-600">
        Find a name that matches a search criteria.
      </p>
      <Dropdown
        value={sel.operator}
        sourceItems={operators}
        onChange={(_, value) => handleSelection("operator", value)}
      />
      <div className="w-full flex-ij space-x-2 py-0.5 -m-1">
        <span>⤹</span>
        <InputField
          required
          width={320}
          value={sel.value}
          onChange={({ target }) => handleSelection("value", target.value)}
        />
      </div>

      <SubmitButton enabled={sel.operator && sel.value} />
    </form>
  );
}
