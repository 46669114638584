import { BiMessageRoundedX as WarnIcon } from "react-icons/bi";
import { Login } from ".";
import { ToastAlert } from "../components";

const Expired = () => {
  return (
    <div>
      <ToastAlert>
        <div className="flexib space-x-2">
          <WarnIcon size={26} />
          <div className="pl-1 flex flex-col leading-tight">
            <p>Oops! Your session has expired.</p>
            <p className="text-xs">Please log again and continue!</p>
          </div>
        </div>
      </ToastAlert>
      <Login />
    </div>
  );
};

export default Expired;
