import { useEffect, useState } from "react";
import { Button, LoadSpinner } from "../../components";
import { useEiirMutation } from "../../repo-query";
import {
  useAleiirt,
  useAxiosPrivate,
  useCallWithRetry,
} from "../../store/hooks";

const importCodes = ["R", "W"];

function ServerNotify({ label }) {
  return (
    <div className="server-progress">
      <span>This may take a few minutes.</span>
      <p>{label}</p>
    </div>
  );
}

export const ServerProgress = ({ serverId, staging, onComplete }) => {
  const [loading, setLoading] = useState(false);
  const { setErrorMessage } = useAleiirt();
  const axiosPrivate = useAxiosPrivate();

  const { withRetry } = useCallWithRetry({
    predicate: (url) =>
      new Promise(async (resolve, reject) => {
        try {
          const { data } = await axiosPrivate(url);
          if (data.status.code === "S") return resolve(true);
          else if (data.status.code === "W") return resolve(false);
          else return reject(data.detail);
        } catch (error) {
          return reject(error);
        }
      }),
    onComplete,
    onSettled: () => setLoading(false),
  });

  const importer = {
    mutationKey: ["importGroups", { serverId }],
    mutationFn: async (url) => await axiosPrivate.post(url, {}),
    onSuccess: (data, url) => {
      const { data: stepfn, message, status } = data || {};
      if (stepfn?.invoked) {
        withRetry(url);
      } else {
        if (status === "success") onComplete();
        else setErrorMessage(message);
      }
    },
    onError: (error) => {
      setErrorMessage(error.message);
      setLoading(false);
    },
  };

  const importMutation = useEiirMutation(importer);

  useEffect(() => {
    const { status } = staging || {};

    if (importCodes.includes(status.code)) {
      // launch the import process
      setLoading(true);

      const stagingUrl = `/mirth/servers/${serverId}/staging/${staging?.stagingId}`;
      importMutation.mutate(stagingUrl);
    }
    // eslint-disable-next-line
  }, [serverId, staging]);

  return (
    <div className="flex mx-3 px-3">
      {staging &&
        (staging.isService ? (
          <ServerNotify label="Updating Repository..." />
        ) : (
          <>
            <ServerNotify label="Importing Mirth Channels" />
            {loading ? (
              <LoadSpinner />
            ) : (
              <div className="flex-ij">
                <Button
                  content={loading ? "Checking..." : "Check Status!"}
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    withRetry();
                  }}
                />
              </div>
            )}
          </>
        ))}
    </div>
  );
};
