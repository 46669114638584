import { Fragment, useMemo, useState } from "react";
import { SimpleButton } from "../components";

const TabContent = ({ visible, label, children }) => {
  return (
    <Fragment key={label} label={label}>
      {children}
    </Fragment>
  );
};

const Tabs = ({ children, className }) => {
  const tabs = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );
  const [currTab, setCurrTab] = useState(tabs[0].props.label);

  let headers = [],
    content = [];
  for (let tab of tabs) {
    const { label, visible, children } = tab.props;
    if (visible === false) continue;

    const active = label === currTab;

    headers.push(
      <SimpleButton
        key={label}
        content={label}
        className={["tab-btn", active ? "active" : ""].join(" ")}
        onClick={() => setCurrTab(label)}
      />
    );
    if (active) content.push(children);
  }

  return (
    <div className="tabs">
      <div className="header">{headers}</div>
      <div className="content">{content}</div>
    </div>
  );
};

export default Tabs;
export { TabContent };
