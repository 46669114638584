import { useState } from "react";
import { RadioGroup } from "../Radio";
import { SubmitButton } from "./SubmitButton";

const choices = [
  { label: "All", value: "all" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export function BooleanFilter({ id, field, onApply }) {
  const [status, setStatus] = useState(null);

  const handleSubmit = async (e) => {
    const context = {
      value: status,
      fnVoid: (oldState) => {
        const { status, ...rest } = oldState;
        return rest;
      },
      updater: (oldState) => {
        return {
          ...oldState,
          [field]: status,
        };
      },
    };

    setStatus(null);
    return context;
  };

  return (
    <form id={id} className="w-[240px] sdrop" onSubmit={onApply(handleSubmit)}>
      <p className="font-semibold">Filter by Status</p>
      <p>Find records with the given status</p>

      <RadioGroup
        name="filter-grp"
        group={choices}
        selectedValue={status}
        onRadioChange={(_, value) => setStatus(value)}
      />
      <SubmitButton enabled={!!status} />
    </form>
  );
}
