import { useState } from "react";
import { PiUsersDuotone as UsersIcon } from "react-icons/pi";
import { Button, RadioGroup } from "../../components";
import { useEiirMutation } from "../../repo-query";
import Restrict from "../../services/Restrict";
import { Urls } from "../../store/axios";
import { useAleiirt, useAxiosPrivate, useReport } from "../../store/hooks";

export const UserReport = () => {
  const [role, setRole] = useState(null);
  const { setErrorMessage } = useAleiirt();
  const { xportControl } = useReport();
  const axiosPrivate = useAxiosPrivate();

  const userReport = {
    mutationKey: ["user-rpts", { role }],
    mutationFn: async () =>
      await axiosPrivate.post(Urls.reports.users, { role }),
    onSuccess: (data) => {
      data.length > 0 && xportControl.exportCSV(data, `${role}-users.csv`);
    },
    onError: (error) => setErrorMessage(error.message),
    onSettled: () => setRole(null),
  };

  const mutation = useEiirMutation(userReport);

  return (
    <div className="flex space-x-5">
      <span className="w-12 h-12 p-2 flex-ij rounded-full border border-slate-400 bg-neutral-50 drop-shadow-sm">
        <UsersIcon color="brown" size={36} />
      </span>
      <div className="grid grid-cols-1 content-between h-full">
        <div className="w-[96%]">
          <span className="font-semibold">Users List</span>
          <p className="mb-3 text-[14px] leading-tight">
            The Users List report contains all user related information such as
            names, statuses, roles and any user properties.
          </p>
        </div>
        <Restrict allowedAction="ExportUsersList">
          <RadioGroup
            name="usersgrp"
            group={userChoices}
            selectedValue={role}
            onRadioChange={(_, value) => setRole(value)}
            className="flex space-x-3"
          />
          <div>
            <Button
              content="Generate CSV Report"
              onClick={() => mutation.mutate()}
              loading={mutation.isLoading}
              disabled={!role}
            />
          </div>
        </Restrict>
      </div>
    </div>
  );
};

const userChoices = [
  { label: "All", value: "all" },
  { label: "Admins Only", value: "admins" },
  { label: "Users Only", value: "users" },
];
