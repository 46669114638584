import { useEffect, useState } from "react";
import { Button, ExpandedArea } from "../../components";
import { useAxiosPrivate, useCommon } from "../../store/hooks";
import { SyncUpdate } from "./SyncUpdate";
import { useEiirMutation } from "../../repo-query";

export const NotifyOption = ({ onClose, onRemove }) => {
  const [showing, setShowing] = useState({});
  const { notifications } = useCommon();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const items = (notifications || []).reduce((disObj, notification) => {
      disObj[notification.id] = true;
      return disObj;
    }, {});
    setShowing(items);
  }, [notifications]);

  const mutation = useEiirMutation({
    mutationKey: ["notifications", { notificationId: "all" }],
    mutationFn: async () => await axiosPrivate.delete("/repo/notifications"),
    onSuccess: () => {
      setShowing({});
      onRemove("all");
      onClose();
    },
  });

  const total = Object.values(showing).filter(Boolean).length;
  const footer = true ? (
    <Button
      outline
      content="Clear All"
      loading={mutation.isLoading}
      onClick={() => mutation.mutate()}
    />
  ) : (
    <span className="py-2 leading-tight">
      You do not have any notifications at this time.
      <br />
      You are all caught up.
    </span>
  );

  return (
    <div id="notify-droparea" className="notify">
      <div className="notify-header">
        <span>Notifications</span>
        <span className="notify-total">{total}</span>
      </div>
      <ol id="repo-scroll">
        {(notifications || [])
          .map((n) => (
            <li key={n.id}>
              <ExpandedArea id={n.id} expanded={showing[n.id]}>
                <SyncUpdate notification={n} onDismiss={(id) => onRemove(id)} />
              </ExpandedArea>
            </li>
          ))
          .filter(Boolean)}
      </ol>
      <div className="notify-footer">
        <div className="inline-flex items-center">{footer}</div>
      </div>
    </div>
  );
};
