export function createQueryMutator(
  client,
  { mutationKey, mutationFn, create = true }
) {
  const __searchQuery = (mutationKey, mutationFn, create) => {
    return client.getQuery({
      queryKey: mutationKey,
      queryFn: mutationFn,
      create,
    });
  };

  const query = __searchQuery(mutationKey, mutationFn, create);

  const mutator = {
    notify: () => {},
    subscribe: (rerender) => {
      mutator.notify = rerender;
      return query.subscribe(mutator);
    },
    getQueryState: () => query.state,
    mutate: (data, options) => query.mutateQuery(data, options),
  };

  return mutator;
}
