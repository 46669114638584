import { FaRegCircleCheck as CheckIcon } from "react-icons/fa6";
import { MdCloudOff as NoIcon } from "react-icons/md";
import { Checkbox } from "../components";

export function channelGroupsColumns(selcetAll, handler) {
  return [
    {
      key: "Select",
      render: (g, i) => {
        const checkbox =
          g.channels === undefined ? (
            <Checkbox
              key="chk-all"
              id="chk-all"
              checked={selcetAll}
              onChange={({ target }) => handler("chk-all", target.checked)}
            />
          ) : g.channels.count ? (
            <Checkbox
              key={g.id}
              id={g.id}
              checked={g.checked}
              onChange={({ target }) => handler(i, target.checked)}
            />
          ) : g.channels.imported === 0 ? (
            <NoIcon color="brown" size={18} />
          ) : null;
        return <section className="pl-2">{checkbox}</section>;
      },
    },
    {
      label: "Channel Groups",
      render: (g) => <p className="py-1">{g.name}</p>,
    },
    {
      label: "Imported",
      render: ({ channels }) => `${channels.imported}/${channels.count}`,
      align: "text-center",
    },
    {
      key: "Status",
      width: 52,
      render: ({ channels }) => {
        if (channels.count > 0) {
          return (
            channels.count === channels.imported && (
              <CheckIcon className="flex space-x-2 text-secondary" size={19} />
            )
          );
        }
      },
    },
  ];
}
