import { PiMediumLogoDuotone as OriginIcon } from "react-icons/pi";
import { SlUserFollowing as PatientIcon } from "react-icons/sl";
import { Handle, Position } from "@xyflow/react";
import { StackedAvatar } from "../components";

function __inlcudes(subservices, selection) {
  if (!selection) return true;
  for (let sub of subservices) {
    if (sub.subservice === selection) return true;
  }
  return false;
}

export function AnnotationNode({ data }) {
  return (
    <>
      <div className="text-xs text-primary mb-2 w-40">{data.label}</div>
      {data.arrowStyle && (
        <div className="absolute" style={data.arrowStyle}>
          ⤹
        </div>
      )}
    </>
  );
}

export function CustomNode(node) {
  const { id, data } = node;

  return (
    <div className="border border-dashed border-neutral-700 p-5 h-[36px] rounded flex-ij text-xs bg-neutral-100">
      <div className="flex-i space-x-1">
        <span>
          <OriginIcon size={22} />
        </span>
        <span>{data.label}</span>
      </div>
      <Handle type="source" position={Position.Bottom} id={id} />
    </div>
  );
}

export function PatientNode() {
  return (
    <div className="flex-ij rounded border border-neutral-300 w-16 p-3 bg-[#efefef] font-semibold">
      <PatientIcon color="#ef4237" size={24} />
      <Handle type="source" position={Position.Right} id="origin" />
    </div>
  );
}

export function ServicelineNode({ id, data, selected }) {
  return (
    <div className="flex-ij w-[124px] py-2 rounded border border-neutral-300 bg-[#efefef] tracking-tight text-[12px]">
      <span className={selected ? "text-[#ef4237]" : ""}>{data.label}</span>
      <Handle type="target" position={Position.Left} id={id} />
    </div>
  );
}

export function SourcesNode({ id, data }) {
  const renderSources = [];
  const { sources, selection } = data;

  Object.entries(sources).forEach(([source, feed]) => {
    const isIncluded = __inlcudes(feed.subservices, selection);
    const services = feed.subservices.reduce((rObj, sub) => {
      if (!rObj.includes(sub.shortname)) rObj.push(sub.shortname);
      return rObj;
    }, []);

    renderSources.push(
      <li
        key={source}
        className={`whitespace-nowrap bg-white flex-grow border-l-2 rounded-md py-0.5 px-2 w-full trans300 ${
          isIncluded ? " border-l-green-600 " : "opacity-50"
        }`}
      >
        <p className="text-neutral-800">{source}</p>
        <div className="text-gray-500 font-thin flex flex-col">
          <span className={`${isIncluded ? "text-violet-600" : ""}`}>
            {feed.source_uuid}
          </span>
          <StackedAvatar labels={services} small />
        </div>
      </li>
    );
  });

  return (
    <div className="p-2 rounded border-t border-neutral-500 w-full text-xs">
      <ol className="grid grid-cols-1 space-y-1 w-full">{renderSources}</ol>
      <Handle type="target" position={Position.Top} id={id} />
    </div>
  );
}
