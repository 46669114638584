import "../assets/css/banner.css";
import { MdClose as CloseIcon } from "react-icons/md";

function notValid(text) {
  return text === undefined || text === null;
}

export const FooterAlert = ({ message }) => {
  return (
    <div className="alert-footer w-full fixed bottom-0 left-0 z-[500]">
      <input
        type="checkbox"
        className="hidden"
        id="footeralert"
        onChange={() => {}}
        checked={notValid(message)}
      />

      <div className="banner-footer">
        {message}
        <label
          className="close cursor-pointer"
          title="close"
          htmlFor="footeralert"
        >
          <CloseIcon />
        </label>
      </div>
    </div>
  );
};

export const ToastAlert = ({ message, children }) => {
  return (
    <div className="alert-toast fixed top-0 right-0 m-8 w-5/6 md:w-full max-w-sm z-30">
      <input type="checkbox" className="hidden" id="headertoast" />

      <label className="banner-toast" title="close" htmlFor="headertoast">
        {message ? <span>{message}</span> : children}
        <CloseIcon />
      </label>
    </div>
  );
};

const Alert = ({ id, danger, message, callback }) => {
  const checked = notValid(message);
  return (
    <div
      className={`alert-banner w-full fixed top-0 left-0 z-0 transition-all duration-300 ${
        checked ? "-mt-12" : ""
      } `}
    >
      <input
        id={id}
        type="checkbox"
        className="hidden"
        checked={checked}
        onChange={() => {
          if (callback) callback();
        }}
      />
      <label
        className={`close py-4 banner-header transition-all duration-300 banner-${
          danger ? "danger" : "success"
        }`}
        title="close"
        htmlFor={id}
      >
        <span />
        {message}
        <CloseIcon />
      </label>
    </div>
  );
};

export default Alert;
