import { memo, useCallback, useState } from "react";
import {
  MdClose as CloseIcon,
  MdNavigateNext as NextIcon,
} from "react-icons/md";
import { Button, Divider, InputField } from "../../../components";
import {
  useEiirForm,
  usePasswordGen,
  useUserRequest,
} from "../../../store/hooks";
import { UserCreate } from ".";
import { required, phone } from "../../../form/validation-rules";

function createConfig() {
  const accountState = {
    family: {
      value: "",
      rules: [required("Family Name")],
    },
    given: { value: "", rules: [required("Given Names")] },
    phone: { value: "", rules: [phone("User Phone Number")] },
    department: { rules: [required("IT Department")] },
    display: { value: "" },
    usertitle: {},
  };

  return accountState;
}

export const AccountCreate = memo(({ onClose }) => {
  const [userEmail, setUserEmail] = useState(null);
  const { userRequest, onNext, createUser } = useUserRequest();
  const { password } = usePasswordGen(10);

  const {
    values: { given, family },
    handleSubmit,
    register,
  } = useEiirForm(createConfig());

  const currClass = userEmail ? "trans-opac" : "opacity-none";
  const display = `${given.value} ${family.value}`.trim();

  const handleEmail = useCallback(
    (email) => {
      if (email) {
        setUserEmail(email);
        createUser(email);
      }
    },
    [createUser]
  );

  const handleClose = useCallback(() => {
    handleEmail();
    onClose();
  }, [handleEmail, onClose]);

  const onAccountSubmit = async (data) => {
    userRequest.setName(data.given, data.family, display);
    userRequest.addPhone(data.phone);
    userRequest.setDepartment(data.usertitle, data.department);
    userRequest.setCredentials(
      userEmail.replace("@envisionhealth.com", ""),
      password
    );
    userRequest.revokePassword();
    onNext();
  };

  return (
    <section className="drawer-section">
      <UserCreate onSetEmail={(email) => handleEmail(email)} />

      <form id="account-form" onSubmit={handleSubmit(onAccountSubmit)}>
        <div className={`accnt ${currClass}`}>
          <p className="text-center mt-1 mb-3">
            Enter the basic information for the user.
          </p>

          <div className="controls">
            <div className="name">
              <InputField
                {...register("given")}
                helperText="Given Names"
                outline
                width={200}
              />
              <InputField
                {...register("family")}
                helperText="Last Name"
                outline
              />
            </div>
            <InputField
              {...register("display")}
              label="Display Name"
              value={display}
              disabled
            />
            <InputField
              {...register("phone", { type: "phone" })}
              label="Phone Number"
              helperText="Home, office, or mobile only"
            />
            <InputField
              {...register("usertitle")}
              label="Title"
              helperText="Department Title"
            />
            <InputField
              {...register("department")}
              label="IT Department"
              helperText="Full department name"
            />
          </div>
        </div>

        <div className={`flexj ${currClass} space-x-2 pt-5 mb-5`}>
          <Button onClick={handleClose}>
            <div className="stepbtn">
              <span>
                <CloseIcon size={18} />
              </span>
              <span className="label pr-1">CANCEL</span>
            </div>
          </Button>
          <Divider />
          <Button type="submit" disabled={userEmail === null}>
            <div className="stepbtn">
              <span className="label pl-1.5">NEXT</span>
              <span>
                <NextIcon size={20} />
              </span>
            </div>
          </Button>
        </div>
      </form>
    </section>
  );
});
