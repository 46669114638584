import { useCallback, useState } from "react";
import useOpenState from "./use-openstate";
import { Drawer, Portal } from "../../components";

const useDrawer = (id, initialDialog, callback = null) => {
  const [dialog, setDialog] = useState(initialDialog || {});
  const { isOpen, showWindow, dismiss } = useOpenState();
  const { title, element, large, ...props } = dialog;

  const DrawerContent = element;

  const closeWindow = useCallback(() => {
    if (callback) callback();
    setDialog({});
    dismiss();
  }, [callback, dismiss]);

  const openDialog = useCallback(
    (config) => {
      setDialog(config);
      showWindow();
    },
    [showWindow]
  );

  return {
    isOpen,
    drawer: (
      <Portal wrapperId={id || "simple-portal"}>
        <Drawer
          title={title}
          isOpen={isOpen}
          large={large}
          onClose={closeWindow}
        >
          {DrawerContent && <DrawerContent {...props} onClose={closeWindow} />}
        </Drawer>
      </Portal>
    ),
    openDialog,
    showWindow,
    closeWindow,
  };
};

export default useDrawer;
