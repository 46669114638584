import { UserRequestProvider } from "../../store/contexts";
import { useUserRequest } from "../../store/hooks";
import {
  AccountCreate,
  ResponseCreate,
  RoleCreate,
  UserReview,
} from "./interactive";

const Switcher = ({ children }) => {
  const { stepIndex } = useUserRequest();

  return children?.map((route, index) => {
    return (
      <section
        key={`route-${index}`}
        className={stepIndex === index ? "h-full" : "hidden"}
      >
        {route}
      </section>
    );
  });
};

const NewUserForm = ({ onCreated }) => {
  return (
    <UserRequestProvider>
      <Switcher>
        <AccountCreate />
        <RoleCreate />
        <UserReview />
        <ResponseCreate onClose={onCreated} />
      </Switcher>
    </UserRequestProvider>
  );
};

export default NewUserForm;
