import { memo } from "react";
import {
  BiLineChart as LocationIcon,
  BiBarChart as SlIcon,
} from "react-icons/bi";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Card, Indicator, Progressbar } from "../../components";
import { useCommon } from "../../store/hooks";
import FormatUtils from "../../utils/formatUtils";

export const FeedCharts = memo(() => {
  const {
    exchangeTypes,
    hospitals,
    feeds,
    serverCount,
    feedCount,
    serviceCount,
    channelCount,
  } = useCommon();

  if (!feeds) return;

  const renderServices = feeds.map((sl) => {
    const percent = sl.total / serviceCount;
    return (
      <li key={sl.serviceline}>
        <div className="flex items-center space-between my-1">
          <span className="tracking-tight w-[55%]">{sl.serviceline}</span>
          <span className="w-[20%] text-end">{sl.total}</span>
          <span className="text-neutral-400 text-end text-[14px] mr-1 w-[25%]">
            {FormatUtils.formatPercentage({
              value: percent,
              sign: true,
              padStart: true,
            })}
          </span>
        </div>
        <Progressbar value={FormatUtils.formatPercentage({ value: percent })} />
      </li>
    );
  });

  return (
    <div className="service-charts">
      <Card sx={{ padding: 16 }}>
        <div className="px-2">
          <span className="title">Lines of Service</span>
          <ol className="space-y-1">{renderServices}</ol>
        </div>
      </Card>

      <Card sx={{ paddingTop: 16, paddingBotton: 8 }}>
        <span className="title">Sources by Exchange Type</span>
        <ResponsiveContainer
          width="100%"
          className="-mt-2"
          height={renderServices.length > 6 ? 276 : 224}
        >
          <BarChart
            layout="horizontal"
            data={exchangeTypes}
            margin={{
              top: 16,
              right: 0,
              bottom: -8,
              left: -4,
            }}
          >
            <CartesianGrid strokeDasharray="4 4" vertical={false} />
            <XAxis
              interval={0}
              dataKey={(e) => `${e.shortname}`}
              type="category"
              scale="auto"
              tick={{ fontSize: 14, letterSpacing: "-0.025em" }}
              width={88}
              tickCount={6}
            />
            <YAxis hide tickCount={6} />
            <Bar
              animationDuration={1000}
              background={{ fill: "#eee" }}
              barSize={24}
              dataKey="value"
              fill="#413ea0"
              label={{ position: "top", backgroundColor: "#fff" }}
              radius={[12, 12, 12, 12]}
              minPointSize={5}
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>

      <div className="space-y-2.5">
        <Card sx={{ padding: 16, height: "45%" }}>
          <span className="title">Traffic Sources</span>
          <div className="traffic">
            <div className="col">
              <span>{channelCount}</span>
              <div>
                <Indicator />
                <span>Channels</span>
              </div>
            </div>
            <div className="col">
              <span>{feedCount?.toLocaleString()}</span>
              <div>
                <Indicator color="warn" />
                <span>Mirth Feeds</span>
              </div>
            </div>
            <div className="col">
              <span>{serverCount}</span>
              <div>
                <Indicator color="ok" /> <span>Servers</span>
              </div>
            </div>
          </div>
        </Card>

        <Card sx={{ padding: 16, height: "52%" }}>
          <p className="font-semibold">Healthcare Facilities</p>
          <span className="leading-[0.5rem]">
            Physical places where services are provided,
            <br />
            grouped into organizations (or divisions).
          </span>
          <div className="hospitals">
            <div className="flex items-center space-x-2 ml-2">
              <span className="locations">
                <SlIcon color="white" size={32} />
              </span>
              <div className="flex flex-col leading-tight">
                <span className="text-[14px]">Locations</span>
                <span className="text-[20px] font-semibold">
                  {hospitals.locations?.toLocaleString()}
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center space-x-2">
                <span className="orgs">
                  <LocationIcon color="white" size={26} />
                </span>
                <div className="flex flex-col leading-tight">
                  <span className="text-[14px]">Divisions</span>
                  <span className="text-[20px] font-semibold">
                    {hospitals.groups?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
});
