import { useCallback, useMemo, useState } from "react";
import { PiCheckCircleDuotone as CheckIcon } from "react-icons/pi";
import { RxCircle as UncheckIcon } from "react-icons/rx";
import {
  Button,
  Card,
  CircularProgressbar,
  Divider,
  FileBrowser,
  LoadSpinner,
} from "../../components";
import { Urls } from "../../store/axios";
import { useDrawer, useUpload } from "../../store/hooks";
import { StatsInfo } from "./StatsInfo";
import { ValidateSettings } from "./ValidateSettings";
import { initialChecks } from "./config";

const MAX_VALUE = 60;
const initialState = { valid: null };
const analysisSX = {
  marginTop: 8,
  height: 240,
  alignContent: "space-between",
  display: "flex",
  flexDirection: "column",
};

export const HL7Analysis = () => {
  const [report, setReport] = useState(initialState);
  const [options, setOptions] = useState(initialChecks);
  const { drawer, openDialog, closeWindow } = useDrawer("optionportal");

  const handleAnalysis = (data) => setReport(data);

  const { loading, analyze } = useUpload(
    "analysis",
    Urls.conformance.analysis,
    handleAnalysis
  );

  const handleClearReport = useCallback(() => setReport(initialState), []);

  const updateOptions = useCallback(
    (checks) => {
      const currOptions = {};
      for (let [id, option] of Object.entries(initialChecks)) {
        option.checked = checks[id];
        currOptions[id] = option;
      }

      setOptions(currOptions);
      closeWindow();
    },
    [closeWindow]
  );

  const handleUpload = useCallback(
    (files) => {
      const checks = {};
      for (let [k, v] of Object.entries(options)) checks[k] = !!v.checked;
      analyze(files, checks);
    },
    [options, analyze]
  );

  const { valid, total } = useMemo(
    () =>
      Object.values(report.messages || {}).reduce(
        (rObj, event) => {
          Object.values(event).forEach((trigger) => {
            rObj.total += trigger.count;
            rObj.valid += trigger.valid;
          });
          return rObj;
        },
        { valid: 0, total: 0 }
      ),
    [report]
  );

  const value = Math.round((10000 * valid) / (total || 1)) / 100;

  return (
    <div className="w-full">
      <main className="flex space-x-2">
        <section className="w-3/5">
          <FileBrowser
            sx={{ height: 240 }}
            onUpload={handleUpload}
            onRemove={handleClearReport}
          />
        </section>

        <section className="w-1/5">
          <Card sx={analysisSX}>
            <ul className="text-[0.92em] tracking-tight text-neutral-700 divide-y p-2 py-1.5 mb-0">
              {Object.entries(options).map(([key, { label, checked }]) => (
                <li key={`option-${key}`} className="flex-i space-x-1">
                  {checked ? (
                    <CheckIcon size={20} />
                  ) : (
                    <UncheckIcon size={18} />
                  )}
                  <Divider />
                  <span>{label}</span>
                </li>
              ))}
            </ul>

            <Button
              outline
              content="Edit Options"
              onClick={() =>
                openDialog({
                  title: "General Configuration",
                  element: ValidateSettings,
                  items: options,
                  onSave: updateOptions,
                  onCancel: closeWindow,
                })
              }
            />
          </Card>
        </section>

        <section className="w-1/5">
          <Card sx={{ marginTop: 8, height: 240 }}>
            <div className="grid grid-cols-1 h-full content-between p-1">
              <div className="text-center h-1/5">
                <span className="text-sm text-neutral-600 font-semibold">
                  HL7 Validation
                </span>
              </div>
              <div className="h-3/5 flex-j">
                {loading ? (
                  <LoadSpinner large />
                ) : (
                  <CircularProgressbar
                    radius={50}
                    selectedValue={value}
                    maxValue={MAX_VALUE}
                    withGradient
                  />
                )}
              </div>
              <div className="text-[14px] tracking-tight leading-tight text-center mx-4 px-4 h-1/5">
                {/* bottom side */}
                <p className="text-neutral-600 text-lg">
                  {valid} of {total}
                </p>
                messages found to be conformant.
              </div>
            </div>
          </Card>
        </section>
      </main>

      <StatsInfo logs={report?.logs || []} />

      {drawer}
    </div>
  );
};
