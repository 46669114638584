// Accessing files and folders can be done by creating a function
// that reads the file system and returns the files and folders.

import { memo, useCallback, useMemo, useState } from "react";
import { BsCheck2Circle as DirectoryIcon } from "react-icons/bs";
import { MdDeleteOutline as DeleteIcon } from "react-icons/md";
import {
  Button,
  Checkbox,
  Divider,
  FileInput,
  FolderInput,
  RepoTooltip,
  Table,
} from "../components";
import { KB, MAX_FILES, MB, useAleiirt } from "../store/hooks";

const acceptedFiles = ".data,.hl7";
const initialState = { all: false };

function getSize(size) {
  const isMega = size >= MB;
  const value = isMega ? size / MB : size / KB;
  return `${Math.round(value * 100) / 100} ${isMega ? "MB" : "KB"}`;
}

const FileBrowser = ({ sx, onUpload, onRemove }) => {
  const [files, setFiles] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState(initialState);
  const [fileLimit, setFileLimit] = useState(false);
  const { setErrorMessage } = useAleiirt();

  const handleCheckChange = useCallback(
    ({ target }) => {
      const { id, checked } = target;

      if (id === "all") {
        const curr = { all: checked };
        for (let index in files) curr[index] = checked;
        setUploadedFiles(curr);
      } else {
        setUploadedFiles((prev) => {
          const uploaded = { ...prev, [id]: checked };

          if (checked) {
            if (
              !uploaded.all &&
              Object.values(uploaded).lastIndexOf(false) === 0
            )
              uploaded.all = checked;
          } else if (uploaded.all) uploaded.all = checked;

          return uploaded;
        });
      }
    },
    [files]
  );

  const handleAddFolder = useCallback(
    (directory) => {
      const size = Object.keys(files).length;
      let limitExceeded = false;

      if (size + directory.count > MAX_FILES) {
        setErrorMessage(`You can only add a maximum of ${MAX_FILES} files`);
        limitExceeded = true;
      }

      if (!limitExceeded) {
        setFiles((prev) => ({ ...prev, [directory.index]: directory }));
        setUploadedFiles((prev) => ({ ...prev, [directory.index]: false }));
        onRemove();
      }
    },
    [files, onRemove, setErrorMessage]
  );

  const handleAddFiles = useCallback(
    (template) => {
      const currFiles = { ...files };
      const currUploaded = { ...uploadedFiles };

      let limitExceeded = false;

      template.some((file) => {
        if (!(file.index in currFiles)) {
          const size = Object.keys(currFiles).length;

          if (size === MAX_FILES) setFileLimit(true);
          if (size > MAX_FILES) {
            setErrorMessage(`You can only add a maximum of ${MAX_FILES} files`);
            limitExceeded = true;
            return true;
          }

          currFiles[file.index] = file;
          currUploaded[file.index] = false;
        }

        return false;
      });

      if (!limitExceeded) {
        setFiles(currFiles);
        setUploadedFiles(currUploaded);
        onRemove();
      }
    },
    [files, uploadedFiles, onRemove, setErrorMessage]
  );

  const handleRemoveSelected = useCallback(() => {
    const currFiles = {};
    const currUploads = { all: false };

    for (let key in files) {
      if (!uploadedFiles[key]) {
        currFiles[key] = files[key];
        currUploads[key] = uploadedFiles[key];
      }
    }

    setFiles(currFiles);
    setUploadedFiles(currUploads);
    onRemove();
  }, [files, uploadedFiles, onRemove]);

  const handleAnalysis = () => onUpload(files);

  const enabled = useMemo(() => {
    return Object.values(uploadedFiles).some(Boolean);
  }, [uploadedFiles]);

  const filesConfig = useMemo(
    () => [
      {
        key: "Select",
        render: ({ index }) => {
          const cbId = index || "all";
          return (
            <Checkbox
              id={cbId}
              key={index || "select-all"}
              sx={{ paddingLeft: 12 }}
              onChange={handleCheckChange}
              checked={uploadedFiles[cbId] || false}
            />
          );
        },
        width: 80,
      },
      {
        label: "Name",
        render: ({ index }) => {
          const splits = index.split("/");
          return splits[splits.length - 1];
        },
      },
      {
        label: "Folder",
        render: ({ isDirectory }) =>
          isDirectory ? (
            <span className="flex-ij text-violet-600 font-semibold">
              <DirectoryIcon size={20} />
            </span>
          ) : (
            "--"
          ),
        align: "text-center",
      },
      {
        label: "Size",
        render: ({ size }) => <p className="my-[5px]">{getSize(size)}</p>,
      },
      {
        label: "Count",
        render: ({ count }) => <p>{count}</p>,
        align: "text-center",
      },
    ],
    [uploadedFiles, handleCheckChange]
  );

  return (
    <>
      <Table
        key="file-browser"
        label="No files in here yet to validate."
        dataSource={Object.values(files)}
        columns={filesConfig}
        sx={sx}
      />
      <form
        encType="multipart/form-data"
        className="space-x-[6px] w-full flex-i justify-end"
      >
        <Button
          icon={{ icon: DeleteIcon, size: 18 }}
          disabled={!enabled}
          onClick={handleRemoveSelected}
        />
        <RepoTooltip label="Add files" position="bottom">
          <FileInput
            multiple
            accept={acceptedFiles}
            disabled={fileLimit}
            onChange={handleAddFiles}
          />
        </RepoTooltip>
        <RepoTooltip label="Add folder" position="bottom">
          <FolderInput
            accept={acceptedFiles}
            disabled={fileLimit}
            onChange={handleAddFolder}
          />
        </RepoTooltip>
        <div
          className={`flex-i space-x-[6px] ${
            Object.values(uploadedFiles).length <= 1
              ? "opacity-none invisible"
              : "trans-opac"
          }`}
        >
          <Divider />
          <Button light content="Analyze" onClick={handleAnalysis} />
        </div>
      </form>
    </>
  );
};

export default memo(FileBrowser);
