import { createContext } from "react";
import { useAlert } from "../hooks";

export const AlertContext = createContext();

export const AlertContextProvider = ({ children }) => {
  const context = useAlert("eiir-alert");
  return (
    <AlertContext.Provider value={{ ...context }}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
