import { Button, Repography } from "../components";
import { useNavigateHistory } from "../store/hooks";

const Unauthorized = () => {
  const { goBack } = useNavigateHistory();
  return (
    <div className="page-err">
      <Repography h2 center text="403" />
      <Repography h6 center text="Forbidden" />
      <Repography
        center
        text="Unfortunately, access to this resource on the server is denied."
      />
      <div>
        <Button content="Go back" onClick={() => goBack()} />
      </div>
    </div>
  );
};

export default Unauthorized;
