import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { TbArrowBackUpDouble as BackIcon } from "react-icons/tb";
import { Button, Dropdown, loadPage, Table } from "../../components";
import { useEiirQuery } from "../../repo-query";
import FlowRender from "../../services/FlowRender";
import { layoutTree, useAxiosPrivate } from "../../store/hooks";
import { getLocationNodes } from "./facilityNodes";

function createRow(feeds) {
  if (!feeds) return [[], []];

  const arr = [],
    serviceArr = new Set();

  Object.entries(feeds).forEach(([org, sources]) => {
    Object.entries(sources).forEach(([source, feed]) => {
      let { subservices, ...rest } = feed;
      const lf = rest;
      lf.source = source;
      lf.organization = org;
      arr.push(lf);
      subservices.forEach((s) => {
        if (s.subservice) serviceArr.add(s.subservice);
      });
    });
  });

  return [arr, Array.from(serviceArr)];
}

function FlowControls({ selection, values, onServiceChange }) {
  return (
    <div className="absolute right-6 top-2 z-10 text-sm min-w-20">
      <Dropdown
        showClearButton
        outline
        helperText="Service Lines"
        width={190}
        value={selection}
        sourceItems={values}
        onChange={(_, sel) => onServiceChange(sel)}
      />
    </div>
  );
}

export const LocationDetails = memo(({ locationId, onClose }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selection, setSelection] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const handleLayout = useCallback((data, selection) => {
    const [nodes, edges] = getLocationNodes(data, selection);
    const layoutedNodes = layoutTree(nodes, edges);
    setNodes(layoutedNodes);
    setEdges(edges);

    // eslint-disable-next-line
  }, []);

  const { data } = useEiirQuery({
    queryKey: ["locations", { locationId }],
    queryFn: async () => await axiosPrivate(`/repo/locations/${locationId}`),
  });

  useEffect(() => {
    if (data) handleLayout(data, selection);
  }, [selection, data, handleLayout]);

  const [feeds, services] = useMemo(() => createRow(data?.feeds), [data]);

  return (
    <section className="flex-ij-col min-h-[400px]">
      {data ? (
        <>
          <Button outline sx={{ width: 120 }} onClick={onClose}>
            <div className="flex space-x-1 items-center">
              <BackIcon size={20} />
              <span>Return to list</span>
            </div>
          </Button>
          <main className="w-full space-y-4">
            <Table loose dataSource={feeds} columns={ldConfig} />
            <div className="feed-flow min-h-[640px]">
              <FlowRender
                initialNodes={nodes}
                initialEdges={edges}
                fitView={false}
                showControls={false}
                zoom={1.25}
              >
                <FlowControls
                  selection={selection}
                  values={services || []}
                  onServiceChange={(sel) => setSelection(sel)}
                />
              </FlowRender>
            </div>
          </main>
        </>
      ) : (
        loadPage()
      )}
    </section>
  );
});

const ldConfig = [
  {
    key: "lf-row",
    render: () => null,
  },
  {
    label: "Source Feed",
    render: (l) => l.source,
    sortValue: (l) => l.source,
  },
  {
    label: "Group",
    render: (l) => l.organization,
    sortValue: (l) => l.organization,
  },
  {
    label: (
      <div className="grid grid-cols-3 gap-x-1">
        <p>eCode</p>
        <p>COCID</p>
        <p>Connect Id</p>
      </div>
    ),
    render: (l) => (
      <div className="grid grid-cols-3 gap-x-1">
        <p>{l.ecode || "--"}</p>
        <p>{l.cocid}</p>
        <p>{l.connectId || "--"}</p>
      </div>
    ),
  },
  {
    label: "Connector",
    render: (l) => l.connector,
  },
  {
    label: "Exchange Type",
    render: (l) => l.exchangeType,
    sortValue: (l) => l.exchangeType,
  },
];
