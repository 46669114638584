import { useState } from "react";
import { Checkbox } from "..";
import { SubmitButton } from "./SubmitButton";

export function CheckFilter({ id, field, options, onApply }) {
  const [values, setValues] = useState(() => options.values.map((_) => false));

  const handleChange = (index) =>
    setValues((prev) => {
      const updated = [...prev];
      updated[index] = !updated[index];
      return updated;
    });

  const handleSubmit = async () => {
    let aliases = [],
      selections = [];

    options.values.forEach(({ alias, connector }, i) => {
      if (values[i]) {
        aliases.push(alias);
        selections.push(connector);
      }
    });

    return {
      value: selections.join(", "),
      fnVoid: (oldState) => {
        const { filter, ...rest } = oldState;
        setValues((prev) => prev.map((v) => false));
        return rest;
      },
      updater: (oldState) => {
        return {
          ...oldState,
          [field]: aliases,
        };
      },
    };
  };

  return (
    <form
      id={id}
      className="min-w-[220px] sdrop"
      onSubmit={onApply(handleSubmit)}
    >
      <div>
        <p className="font-semibold">{options.title}</p>
        <div className="my-3">
          {options.values.map((source, i) => (
            <Checkbox
              key={i}
              id={`check-${source.connector}`}
              label={source.alias}
              onChange={() => handleChange(i)}
              checked={values[i]}
            />
          ))}
        </div>
      </div>

      <SubmitButton enabled={true} />
    </form>
  );
}
