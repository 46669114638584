import { memo, useCallback, useEffect, useState } from "react";
import { ImUserPlus as NewIcon } from "react-icons/im";
import { MdSearch } from "react-icons/md";
import {
  Card,
  GroupInput,
  IconButton,
  RepoTooltip,
  Table,
} from "../../components";
import Pagination from "../../components/Pagination";
import { UserAccount } from "../../pages";
import { useDrawer } from "../../store/hooks";
import NewUserForm from "./NewUserForm";
import { getUserTableConfig } from "../../columns/user-columns";

const UserList = ({
  users,
  profileId,
  isLoading,
  pagination,
  onCreated,
  onChange,
  onView,
}) => {
  const [filter, setFilter] = useState("");
  const { drawer, openDialog, closeWindow } = useDrawer("userportal");

  useEffect(() => {
    onChange(filter);
  }, [filter, onChange]);

  const handleChange = (name) => name !== undefined && setFilter(name);

  const handleClose = useCallback(() => {
    onCreated();
    closeWindow();
  }, [onCreated, closeWindow]);

  const handleAddUserClick = useCallback(() => {
    openDialog({
      title: "Create New User",
      element: NewUserForm,
      onCreated: handleClose,
    });
  }, [handleClose, openDialog]);

  return profileId ? (
    <UserAccount userId={profileId} onReturn={onView} />
  ) : (
    <>
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 24,
        }}
      >
        <section className="flex-ij space-x-3 px-2">
          <div className="space-x-1">
            <span className="text-[15px] font-semibold text-gray-800">
              Users
            </span>
            <span>( {pagination.totalCount?.toLocaleString()} )</span>
          </div>

          <span className="user" onClick={handleAddUserClick}>
            <RepoTooltip position="right" label="Add user">
              <IconButton icon={<NewIcon size={20} />} />
            </RepoTooltip>
          </span>
        </section>
        <div className="search mr-5">
          <GroupInput
            id="usersearch-input"
            width={260}
            lead={{ icon: <MdSearch size={20} color="gray" /> }}
            placeholder="Search user by name"
            value={filter}
            onChange={handleChange}
          />
        </div>
      </Card>

      <div className="users">
        <div className="w-full">
          <Table
            loading={isLoading}
            dataSource={users.results}
            columns={getUserTableConfig((id) => onView(id))}
          />
          <Pagination {...pagination} />
        </div>
      </div>

      {drawer}
    </>
  );
};

export default memo(UserList);
