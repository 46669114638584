import { useState } from "react";
import Dropdown from "../Dropdown";
import { SubmitButton } from "./SubmitButton";

export function ComboFilter({ field, id, options, onApply }) {
  const [selection, setSelection] = useState("");

  const handleSelection = (_, sel) => setSelection(sel);

  const handleSubmit = async () => {
    setSelection("");
    return {
      value: selection,
      fnVoid: (oldState) => {
        const { filter, ...rest } = oldState;
        delete filter[field];
        return { ...rest, filter };
      },
      updater: (oldState) => {
        return {
          ...oldState,
          filter: {
            ...oldState.filter,
            [field]: { operator: "contains", value: selection },
          },
        };
      },
    };
  };

  return (
    <form
      id={`${id}-form`}
      className="w-[300px] sdrop"
      onSubmit={onApply(handleSubmit)}
    >
      <p className="font-semibold">{options.title}</p>
      <p>{options.subtitle}</p>
      <Dropdown
        value={selection}
        sourceItems={options.sources}
        onChange={handleSelection}
      />
      <SubmitButton enabled={Boolean(selection)} />
    </form>
  );
}
