import { useCallback, useEffect, useState } from "react";

/* useDebounce Hook

    This hook allows completion after the given delay
    and returns the value and setter function.
  */
const useDebounce = (delay, initValue, debounceFn = null) => {
  const [value, setValue] = useState(initValue);
  const [debounceValue, setDebounceValue] = useState(value);

  const handleChange = useCallback((value) => {
    setValue(value);
  }, []);

  //  Set debounceValue the same as value
  //  just after the delay.
  //  Note:
  //    We simply clear the interval for every keypress event on cleanup.
  useEffect(() => {
    const debounceId = setTimeout(() => {
      if (debounceFn && value !== null) debounceFn(value);
      else setDebounceValue(value);
    }, delay || 500);
    return () => clearTimeout(debounceId);

    // eslint-disable-next-line
  }, [value, delay]);

  //  Ensure that the hook can be used
  //  just like the useState hook
  return [handleChange, debounceValue, value];
};

export default useDebounce;
