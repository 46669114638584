import { useEffect, useMemo, useRef } from "react";
import { Pagination, Table } from "../../components";
import { FilterPanel, useMediator } from "../../components/eiir-filter";
import { useEiirQuery } from "../../repo-query";
import { handleSearch, Urls } from "../axios";
import useAxiosPrivate from "./use-axios";

function Container({ children }) {
  return children;
}

const useTable = ({ resource, columns, options }) => {
  const [filters, mediator] = useMediator(options);
  const filterComps = useRef([]);
  const axiosPrivate = useAxiosPrivate();

  const { isLoading, data, refetch } = useEiirQuery({
    queryKey: [resource, { ...filters }],
    queryFn: async () =>
      await axiosPrivate.post(Urls.search[resource], filters),
    enabled: false,
    staleTime: 0,
  });

  useEffect(() => {
    if (Object.keys(options).length === 0) return;
    filterComps.current = mediator.render();
    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [filters]);

  const { results, total } = useMemo(() => handleSearch(data), [data]);

  const pagination = {
    mediator,
    totalCount: total,
    currPage: filters.page,
    pageSize: filters.pageSize,
  };

  return (
    <Container>
      <FilterPanel>{filterComps.current}</FilterPanel>
      <Table loading={isLoading} dataSource={results} columns={columns} />
      <Pagination {...pagination} />
    </Container>
  );
};

export default useTable;
