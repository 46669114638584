import "../assets/css/tooltip.css";

const RepoTooltip = ({ id, children, label, position = "left" }) => {
  return (
    <main key={id} data-tip={label} className={`tooltip ${position}`}>
      {children}
    </main>
  );
};

export default RepoTooltip;
