import { useCallback, useRef, useState } from "react";
import { Accordion, Button } from "../../components";
import { useAuth, useAxiosPrivate, useOnce } from "../../store/hooks";
import { PolicyRow } from "./PolicyRow";
import { useEiirMutation } from "../../repo-query";

export const ShowPolicies = ({ publicId, permissions, onUpdate }) => {
  const isMounted = useRef(false);
  const axiosPrivate = useAxiosPrivate();
  const [toggles, setToggles] = useState({});
  const {
    auth: { permissions: allowedActions },
  } = useAuth();

  useOnce(() => {
    if (isMounted.current) return;
    isMounted.current = true;

    if (permissions) setToggles({ actions: permissions });
  });

  const mutator = useEiirMutation({
    mutationFn: async (actions) =>
      await axiosPrivate.patch(`/users/${publicId}/actions`, { actions }),
    onSuccess: ({ message }) => onUpdate(message),
    onError: ({ response }) => console.log(response),
  });

  const handleToggle = useCallback((category, id, allowed) => {
    setToggles((curr) => {
      const categoryObj = curr.actions[category][id];
      return {
        editing: true,
        actions: {
          ...curr.actions,
          [category]: {
            ...curr.actions[category],
            [id]: { ...categoryObj, allowed },
          },
        },
        changes: {
          ...curr.changes,
          [id]: allowed,
        },
      };
    });
  }, []);

  const handleUpdate = () => mutator.mutate(toggles.changes);

  const handleCancel = () => setToggles({ actions: permissions });

  const items = Object.entries(toggles.actions || []).map(
    ([category, values]) => {
      const renderActions = [];

      for (let action of Object.values(values)) {
        renderActions.push(
          <PolicyRow
            key={action.actionId}
            category={category}
            action={action}
            editable={!!allowedActions["EditUserPolicies"]}
            onToggle={handleToggle}
          />
        );
      }

      return (
        renderActions.length > 0 && {
          label: category,
          content: (
            <div className="w-full grid grid-cols-1 gap-0 mr-2.5 pr-2.5 pl-1.5">
              {renderActions}
            </div>
          ),
        }
      );
    }
  );

  return (
    <div className="flex flex-col space-y-2 w-[300px]">
      <Accordion items={items} />
      <div
        className={`flex-i justify-end space-x-1 transition-opacity duration-300 ${
          toggles.editing ? "visible opacity-100" : "invisible opacity-0"
        }`}
      >
        <Button content="Cancel" outline onClick={handleCancel} />
        <Button
          content="Confirm"
          loading={mutator.isLoading}
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};
