import { memo, useCallback, useEffect, useState } from "react";
import { PiDownloadDuotone as NewIcon } from "react-icons/pi";
import { Button } from "../../components";
import { useDrawer } from "../../store/hooks";
import DownloadGroupsForm from "./DownloadGroupsForm";
import { ServerForm } from "./ServerForm";
import { ServerManager } from "./ServerManager";

function ServerConnect({ onConnect }) {
  return (
    <div className="h-full flex-ij-col">
      <div className="mb-5">
        <Button outline onClick={onConnect}>
          <div className="newserver">
            <span className="avatar">
              <NewIcon size={48} />
            </span>
            <span className="sbt">Add Server</span>
          </div>
        </Button>
      </div>
      <p className="tcenter">Connect a new server - it only takes</p>
      <p className="tcenter">a few seconds.</p>
    </div>
  );
}

const ServerList = ({ data, onInvalidate, onMutation }) => {
  const [managers, setManagers] = useState([]);
  const { drawer, openDialog } = useDrawer("server-portal");

  const addServer = useCallback(
    (server) => {
      return (
        <li key={server.serverId}>
          <ServerManager
            server={server}
            onInvalidate={onInvalidate}
            onSync={(isUpdate) => {
              const dlg = isUpdate
                ? {
                    server,
                    isUpdate: true,
                    title: "Mirth Server Settings",
                    element: ServerForm,
                    onMutation,
                  }
                : {
                    title: "Mirth Client API",
                    element: DownloadGroupsForm,
                    serverName: server.name,
                    serverId: server.serverId,
                    onInvalidate,
                  };
              return openDialog(dlg);
            }}
          />
        </li>
      );
    },
    [openDialog, onInvalidate, onMutation]
  );

  useEffect(() => {
    if (!data || data.length === 0) return;

    const managers = data.map(addServer);
    managers.push(
      <li key="newserver">
        <ServerConnect
          onConnect={() =>
            openDialog({
              title: "Add New Server",
              element: ServerForm,
              isUpdate: false,
              onMutation,
            })
          }
        />
      </li>
    );
    setManagers(managers);
  }, [data, addServer, openDialog, onMutation]);

  return (
    <>
      {managers}
      {drawer}
    </>
  );
};

export default memo(ServerList);
