import { useCallback, useEffect, useState } from "react";
import { Animate, Skeleton, TabContent, Table, Tabs } from "../../components";
import { Urls } from "../../store/axios";
import { useUpload } from "../../store/hooks";
import { DocumentViewer } from "./DocumentViewer";
import { Droparea } from "./Droparea";
import EncodedDocument from "./EncodedDocument";
import { Interpretation } from "./Interpretation";
import { MessageValidation } from "./MessageValidation";
import { messageConfig } from "./config";

const CARRIAGE_RETURN = "\r";
// const LINE_FEED = "\n";
const EOL = "\r\n";

export const MessageOverview = () => {
  const [result, setResult] = useState({});
  const [decodedData, setDecodedData] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [document, setDocument] = useState({});
  const [isAtomic, setIsAtomic] = useState(true);

  const handleResult = (data) => setResult(data);
  const handleClose = () => setDocument({});

  const handleReset = useCallback(() => {
    setResult({});
    setDecodedData([]);
    setDocument({});
    setSelectedFile({});
  }, []);

  const handleViewDocument = useCallback(
    (note) => {
      const index = parseInt(note.setId) - 1;
      const data = isAtomic ? decodedData[index] : decodedData.join("");
      setDocument({ note, data });
    },
    [decodedData, isAtomic]
  );

  const decodeHandler = useCallback((segline) => {
    // create segment fields
    const fields = segline.split("|");
    // Get the encapsulated data for the observation
    // and the first base64 string fromt he data
    const ed = fields[5].split("~")[0];
    // slice: [subtype, encoding, data]
    const slices = ed.split("^").slice(2);

    if (slices[0].includes("HTML")) {
      const b64 = slices[2].replaceAll("\\.br\\", "\n");
      return atob(b64);
    } else if ("PDF, IMAGE, RTF".includes(slices[0])) {
      return slices[2];
    }
  }, []);

  const handleChange = useCallback(
    (template) => {
      const { file, index, size } = template[0];
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        const text = fileReader.result,
          segs = [],
          notes = [];

        /**
         * \r = CR (Carriage Return) → Used as a new line character in Mac OS before X
         * \n = LF (Line Feed) → Used as a new line character in Unix/Mac OS X
         * \r\n = CR + LF → Used as a new line character in Windows
         */
        const delimiter = text.includes(EOL) ? EOL : CARRIAGE_RETURN;
        const lines = text.split(delimiter);

        for (let i in lines) {
          const segline = lines[i];

          // trimmed empty lines
          if (!segline) continue;

          // decode encapsulated data if OBX segment
          if (segline.startsWith("OBX") && segline.includes("|ED|"))
            notes.push(decodeHandler(segline));

          segs.push({
            index: parseInt(i) + 1,
            segment: segline.trim(),
          });
        }

        if (notes.length > 0) setDecodedData(notes);
        setSelectedFile({
          content: segs?.filter(Boolean),
          name: index,
          size,
          file,
        });
      };

      Promise.all([handleReset()]).then(() => {
        fileReader.readAsText(file);
      });
    },
    [decodeHandler, handleReset]
  );

  const { layOverview, isLoading } = useUpload(
    "overview",
    Urls.conformance.overview,
    handleResult
  );

  useEffect(() => {
    if (selectedFile.name) layOverview([selectedFile], isAtomic);
  }, [selectedFile, isAtomic, layOverview]);

  return (
    <>
      <Animate>
        <DocumentViewer {...document} onClose={handleClose} />
      </Animate>
      <div
        className={`space-y-4 w-full${document.data ? " hidden-scale" : ""}`}
      >
        <main className="flex space-x-0 w-full text-[15px]">
          <section className="grid grid-cols-1 w-full space-y-4">
            <Droparea
              selectedFile={selectedFile}
              errors={result.errors}
              isAtomic={isAtomic}
              onChange={handleChange}
              onCombine={(checked) => setIsAtomic(!checked)}
            />
            <Table
              verticalOnly={false}
              dataSource={selectedFile.content}
              columns={messageConfig}
              sx={{ maxHeight: 320, minHeight: 100 }}
            />
            {isLoading ? (
              <Skeleton list={{ rows: 2, cols: 3 }} />
            ) : (
              <Tabs>
                <TabContent
                  label="Interpretation"
                  visible={Boolean(result.event)}
                >
                  <Interpretation
                    event={
                      result.event
                        ? `${result.event} ~ ${result.description}`
                        : null
                    }
                    terms={result.translations || []}
                  />
                </TabContent>
                <TabContent
                  label="Validation"
                  visible={result.logs?.length > 0}
                >
                  <MessageValidation logs={result.logs || []} />
                </TabContent>

                <TabContent label="Notes" visible={result.notes?.length > 0}>
                  <EncodedDocument
                    notes={result.notes}
                    onView={handleViewDocument}
                  />
                </TabContent>
              </Tabs>
            )}
          </section>
        </main>
      </div>
    </>
  );
};
