import { Position } from "@xyflow/react";

export const servicelines = [
  "Anesthesia",
  "Emergency Medicine",
  "Hospitalist Medicine",
  "Women's & Chiildren",
  "Radiology",
];

const defaultStyle = {
  animated: true,
  style: { stroke: "#ef4237" },
};

export function serviceNodes(element) {
  const { location, mirthfeed, subservices } = element;
  const subs = subservices.map((s) => s.serviceline);

  const originNode = {
    id: "origin",
    type: "patNode",
    position: { x: 0, y: 95 },
  };

  const facilityNode = {
    id: "facility",
    data: { label: location.name },
    style: { width: 200 },
    position: { x: 90, y: 90 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  };

  const pateintAnnotation = {
    id: "annotation-1",
    type: "annotation",
    draggable: false,
    selectable: false,
    data: {
      level: 1,
      label: "Patient seen at facility.",
      arrowStyle: {
        right: 0,
        bottom: 0,
        transform: "translate(-30px,10px) rotate(80deg)",
      },
    },
    position: { x: 110, y: 60 },
  };

  const feedNode = {
    id: "feed",
    data: { label: mirthfeed.source },
    position: { x: 320, y: 100 },
    style: { width: "auto" },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  };

  const feedAnnotation = {
    id: "annotation-2",
    type: "annotation",
    draggable: false,
    selectable: false,
    data: {
      label: `${mirthfeed.dataformat} ${mirthfeed.exchangeType} sent downstream through`,
      arrowStyle: {
        right: 0,
        bottom: 0,
        transform: "translate(-30px,10px) rotate(100deg) rotateX(180deg)",
      },
    },
    position: { x: 340, y: 55 },
  };

  const edges = [
    {
      id: "origin-facility",
      source: "origin",
      target: "facility",
      ...defaultStyle,
    },
    {
      id: "facility-feed",
      source: "facility",
      target: "feed",
      ...defaultStyle,
    },
  ];

  const servicelineNodes = servicelines.map((serviceline, i) => {
    let id = `sl${i}`;
    let newNode = {
      id,
      type: "serviceNode",
      data: { label: serviceline },
      position: { x: 672, y: i * 50 },
    };
    let newEdge = {
      id: `e${feedNode.id}-${id}`,
      source: feedNode.id,
      target: id,
    };

    if (subs.includes(serviceline)) {
      newNode.style = { color: "#ef4237" };
      newEdge = { ...newEdge, ...defaultStyle };
    }

    edges.push(newEdge);
    return newNode;
  });

  const nodes = [
    originNode,
    pateintAnnotation,
    facilityNode,
    feedAnnotation,
    feedNode,
    ...servicelineNodes,
  ];
  return [nodes, edges];
}
