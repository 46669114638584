import { Button } from ".";
import { useNavigateHistory } from "../store/hooks";

const Link = ({ to, label }) => {
  const { goTo } = useNavigateHistory();

  return <Button outline content={label} onClick={() => goTo(to)} />;
};

export default Link;
