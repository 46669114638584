import { memo } from "react";
import { GiPayMoney as CashIcon } from "react-icons/gi";
import {
  GrSchedule as ScheduleIcon,
  GrDocumentText as TranscriptIcon,
} from "react-icons/gr";
import { MdContentCopy as PolicyIcon } from "react-icons/md";
import { PiCheckCircleDuotone as CheckIcon } from "react-icons/pi";
import {
  TbBuildingHospital as EncounterIcon,
  TbCode as HeaderIcon,
  TbBrandBandlab as LabIcon,
  TbPrescription as OrderIcon,
  TbBrandXing as OtherIcon,
  TbCurrencyDollar as PaymentIcon,
} from "react-icons/tb";
import { TfiUser as PersonIcon } from "react-icons/tfi";
import { Card } from "../../components";

function createItem(key, Icon, header, description, display) {
  if (!Icon) return;

  return (
    <li key={key} className="mb-5 ms-8 w-[75%]">
      <span className="absolute text-blue-800 flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-8 ring-white">
        <Icon size={20} />
      </span>
      <div className="flex space-x-1 items-center justify-start">
        <h3 className="flex items-center font-semibold text-gray-900 text-[14px]">
          {description}
        </h3>
        {header && (
          <span className="block text-sm font-normal leading-none text-gray-400">
            ( {header} )
          </span>
        )}
      </div>
      <div className="mb-4 font-normal text-gray-700">{display}</div>
    </li>
  );
}

const chargeRule = {
  shouldRun: (header) => header === "FT1",
  run: (index, description, term) => {
    let display =
      term.length > 0 ? (
        <ol className="flex flex-col space-y-3">
          {term?.map((charge) => (
            <li
              key={`charge-${charge.order}`}
              className="leading-tight flex space-x-2 w-full flex-i"
            >
              <span className="p-3 rounded border bg-slate-50">
                <PaymentIcon size={20} color="green" />
              </span>
              <div>
                <p className="font-semibold text-[14px]">
                  {charge.type.transaction}
                </p>
                <p>
                  {charge.quant} {charge.procedure} at ${charge.units} ($
                  {charge.amount} total)
                </p>
              </div>
            </li>
          ))}
        </ol>
      ) : (
        <p>No transaction available</p>
      );

    return createItem(index, CashIcon, "FT1", description, display);
  },
};

const patientRule = {
  shouldRun: (header) => header === "PID",
  run: (index, description, term) => {
    let buffer = [term.fullName];
    if (term.mrn) buffer.push(`(MRN: ${term.mrn})`);
    if (term.age) buffer.push(term.age);
    if (term.identity) {
      const { gender, race } = term.identity;
      buffer.push(`${race || ""} ${gender || ""}`.trim());
    }
    return createItem(index, PersonIcon, "PID", description, buffer.join(", "));
  },
};

const visitRule = {
  shouldRun: (header) => header === "PV1",
  run: (index, description, term) => {
    const texts = [],
      providerList = Object.entries({
        admiter: "Admitted",
        referrer: "Referred",
        attender: "Attended",
      }).reduce((rObj, [key, value]) => {
        if (key in term) {
          let provider = term[key];
          if (provider) {
            if (provider in rObj) rObj[provider].push(value.toLowerCase());
            else rObj[provider] = [value];
          }
        }
        return rObj;
      }, {});

    if (term.patientClass) texts.push(`${term.patientClass} visit`);
    if (term.admittedOn)
      texts.push(`on ${term.admittedOn} (${term.timeElapsed} ago)`);
    if (term.location) texts.push(`at ${term.location}`);

    let display = (
      <div>
        <p>{texts.join(" ")}</p>
        {Object.entries(providerList).map(([p, action]) => (
          <p key={action}>{` - ${action.join(", ")} by ${p}`}</p>
        ))}
      </div>
    );

    return createItem(index, EncounterIcon, "PV1", description, display);
  },
};

const policyRule = {
  shouldRun: (header) => header === "IN1",
  run: (index, description, term) => {
    let display =
      term.length > 0 ? (
        <ol className="list-outside ml-2 space-y-1">
          {term.map((policy) => {
            const buffer = [`${policy.insured || "Unknown person"}`];
            buffer.push(` insured by ${policy.by}`);
            if (policy.expiration)
              buffer.push(` and expires on ${policy.expiration}`);

            return (
              <li key={policy.order} className="flex space-x-3 items-center">
                <PolicyIcon size={20} />
                <div className="leading-tight">
                  <p className="flex-i space-x-2">
                    <span>{`${policy.order} Policy:`}</span>
                  </p>
                  <p>{buffer.join("")}</p>
                  <p>
                    {policy.effective && `effective ${policy.effective}`}{" "}
                    {policy.expiration && `and expires on ${policy.expiration}`}
                  </p>
                </div>
              </li>
            );
          })}
        </ol>
      ) : (
        <p>No coverage available</p>
      );

    return createItem(index, PolicyIcon, "IN1", description, display);
  },
};

const commonRule = {
  shouldRun: (header) => header === "ORC",
  run: (index, description, term) => {
    let display,
      buffer = [];

    if (term.filler) buffer.push(`Order Placer No ${term.filler}`);
    if (term.control) buffer.push(term.control);

    display = buffer.join(" ");

    if (term.orderStatus) display = `${display}: ${term.orderStatus}`;
    return createItem(index, OrderIcon, "ORC", description, display);
  },
};

const requestRule = {
  shouldRun: (header) => header === "OBR",
  run: (index, description, term) => {
    const display = [term.diagServiceId];

    if (term.provider) display.push(`ordered by ${term.provider}`);
    display.push(`for ${term.serviceId}`);
    display.push(`on ${term.requestedOn}`);

    return createItem(index, LabIcon, "OBR", description, display.join(" "));
  },
};

const scheduleRule = {
  shouldRun: (header) => header === "SCH",
  run: (index, description, term) => {
    let buffer = [];

    buffer.push(term.date);
    if (term.reason) buffer.push(`for ${term.reason}`);

    let display = (
      <div className="">
        {term.appointment}
        <p>{buffer.join(" ")}</p>
      </div>
    );
    return createItem(index, ScheduleIcon, "SCH", description, display);
  },
};

const resourceRule = {
  run: (index, term) => {
    term.sort();

    const resources = term.map((r, i) => (
      <li key={`resource-${i}`} className="flex-i space-x-2 pt-0.5">
        <CheckIcon size={20} color="green" />
        <span>{r}</span>
      </li>
    ));
    return createItem(
      index,
      OtherIcon,
      null,
      "Resources",
      <ol className="ml-1 w-auto">{resources}</ol>
    );
  },
};

const transcriptRule = {
  shouldRun: (header) => header === "TXA",
  run: (index, description, term) => {
    let buffer = [term.status];

    if (term.format) buffer.push(term.format);
    buffer.push(term.type);

    if (term.originator) buffer.push(`originated by ${term.originator}`);
    if (term.datetime) buffer.push(`on ${term.datetime}`);

    return createItem(
      index,
      TranscriptIcon,
      "TXA",
      description,
      buffer.join(" ")
    );
  },
};

const rules = {
  FT1: chargeRule,
  IN1: policyRule,
  PID: patientRule,
  PV1: visitRule,
  ORC: commonRule,
  OBR: requestRule,
  TXA: transcriptRule,
  SCH: scheduleRule,
};

export const Interpretation = memo(({ event, terms }) => {
  let resources = [],
    renderTerms = [];

  if (event)
    renderTerms.push(
      createItem("header", HeaderIcon, "MSH", "Message Header", event)
    );

  terms.forEach(({ header, description, term }, index) => {
    if (header in rules)
      renderTerms.push(rules[header].run(index, description, term));

    switch (header) {
      case "AIG":
      case "AIL":
      case "AIP":
      case "AIS":
        term.forEach((action) => {
          let text = Object.values(action).filter(Boolean).join(" ");
          resources.push(text);
        });
        break;
      default:
        break;
    }

    return null;
  });

  if (resources.length > 0)
    renderTerms.push(resourceRule.run(renderTerms.length, resources));

  return (
    <Card>
      {renderTerms ? (
        <ul className="relative my-6 mx-14 broder-s border-gray-300">
          {renderTerms}
        </ul>
      ) : (
        <span className="p-4 flex-ij text-sm text-neutral-400">
          No translation terms available
        </span>
      )}
    </Card>
  );
});
