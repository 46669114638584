import { forwardRef, useEffect } from "react";
import { useDebounce } from "../../store/hooks";

const GroupInput = (
  {
    lead = {},
    width = 240,
    placeholder,
    helperText,
    right,
    value,
    onChange,
    ...props
  },
  ref
) => {
  const [setValue, debounce] = useDebounce(300);
  const handleChange = ({ target }) => setValue(target.value || "");

  useEffect(() => {
    if (onChange && !(debounce === null && debounce === undefined))
      onChange(debounce);
    // eslint-disable-next-line
  }, [debounce]);

  let renderLeft, renderRight;

  if (right)
    renderRight = <span className="gright">{lead.icon || lead.text}</span>;
  else renderLeft = <span className="gleft">{lead.icon || lead.text}</span>;

  return (
    <div className="ginput">
      <section
        className="flex-ij border-b border-neutral-400 px-2"
        style={{ width }}
      >
        {renderLeft}
        <input
          ref={ref}
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          defaultValue={value}
          {...props}
        />
        {renderRight}
      </section>
      {helperText && <div className="helper">{helperText}</div>}
    </div>
  );
};

export default forwardRef(GroupInput);
