import {
  SlUserUnfollow as ErrorIcon,
  SlUserFollowing as SuccessIcon,
} from "react-icons/sl";
import { Button } from "../../../components";
import { useUserRequest } from "../../../store/hooks";

export const ResponseCreate = ({ onClose }) => {
  const { response, userRequest } = useUserRequest();
  const isSuccess = response.status === "success";

  return (
    <section className="drawer-section">
      <div className="response">
        {isSuccess ? (
          <>
            <span className="success-icon">
              <SuccessIcon />
            </span>
            <p className="success">
              New user has been successfully created and added to the system.
            </p>

            <div>
              <p className="title">Verication email sent to:</p>
              <span className="email">{userRequest.email}</span>
              <div>
                <span className="pwd">Temp Password:</span> {userRequest.temp()}
              </div>
            </div>
          </>
        ) : (
          <>
            <span className="err-icon">
              <ErrorIcon />
            </span>
            <p className="err-title">Oops! Something went wrong!</p>
            <div className="err-message">{response.message}</div>
          </>
        )}
      </div>
      <div className="flexj mb-5">
        <Button content="Close Window" onClick={() => onClose(isSuccess)} />
      </div>
    </section>
  );
};
