import { useCallback, useState } from "react";
import { BsCheck2Circle as VerifiedIcon } from "react-icons/bs";
import { Button, InputField } from "../../../components";
import {
  isValidEmail,
  required,
  validationHandler,
} from "../../../form/validation-rules";
import { useEiirMutation } from "../../../repo-query";
import { Urls } from "../../../store/axios";
import { useDebounce, useUserRequest } from "../../../store/hooks";

const emailRules = [required("Email"), isValidEmail("Account")];

export const UserCreate = ({ onSetEmail }) => {
  const [account, setAccount] = useState({});
  const { apiClient } = useUserRequest();

  const verifier = {
    mutationKey: ["users", "verifyUser"],
    mutationFn: async (email) => await apiClient.post(Urls.verify, { email }),
    onSuccess: (data) => {
      if (data.valid) {
        onSetEmail(data.email);
        setAccount((curr) => ({ ...curr, verified: true }));
      } else
        setAccount((curr) => ({
          ...curr,
          error: "An account with this email already exists",
        }));
    },
    onError: (error) => {
      setAccount((curr) => ({
        ...curr,
        verified: false,
        error: error.message,
      }));
    },
  };

  const mutation = useEiirMutation(verifier);

  const handleBlur = useCallback((email) => {
    const { valid, error } = validationHandler(emailRules, email);
    setAccount({ email, error, valid });
  }, []);

  const [setInputValue] = useDebounce(500, null, handleBlur);

  const handleEmailChange = useCallback(
    ({ target }) => {
      setInputValue(target.value);
    },
    [setInputValue]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (account.valid && !account.message) mutation.mutate(account.email);
  };

  return (
    <div className="flexj">
      <form onSubmit={handleSubmit} className="flex my-4 ml-2 space-x-4">
        <InputField
          required
          id="username"
          label="Email Address"
          helperText="Envision Healthcare email address"
          width={340}
          errorMessage={account.error}
          onChange={handleEmailChange}
          onBlur={({ target }) => handleBlur(target.value)}
        />

        <div className="mt-1">
          {account.verified ? (
            <VerifiedIcon className="text-[28px] text-green-600 mb-4" />
          ) : (
            <Button
              sx={{ width: 100 }}
              type="submit"
              disabled={!account.valid}
              loading={mutation.isLoading}
            >
              <div className="stepbtn">
                <span className="label">Verify Now</span>
              </div>
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
