import { useEffect, useRef } from "react";
import { useEiirQueryClient } from ".";
import { useForceUpdate } from "../store/hooks";
import { createQueryMutator } from "./createQueryMutator";

const useEiirMutation = ({ mutationKey, mutationFn, ...options }) => {
  const { forceUpdate } = useForceUpdate();
  const client = useEiirQueryClient();
  const mutator = useRef(
    createQueryMutator(client, { mutationKey, mutationFn })
  );

  useEffect(() => {
    const __mutator = mutator.current;
    return __mutator.subscribe(forceUpdate);
  }, [forceUpdate]);

  const mutation = {
    ...mutator.current.getQueryState(),
    mutate: (data) => mutator.current.mutate(data, options),
  };

  return mutation;
};

export default useEiirMutation;
