import { Component } from "react";
import { delayEvent } from "../store/hooks/use-delay";

class AnimatedPage extends Component {
  _mounted = false;

  constructor(props) {
    super(props);

    this.state = {
      slide: props.slide,
      css: props.slide ? "-left-0" : "opacity-0",
    };

    this.transitionEnd = this.transitionEnd.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    void delayEvent(100).then(() =>
      this.setState({
        css: this.state.slide
          ? "transform transition ease-in duration-500 sm:duration-700 translate-x-1/2 overflow-hidden"
          : "transition-opacity ease-in duration-300 opacity-100",
      })
    );
  }

  componentWillUnmount() {
    this._mounted = false;
    this.setState({ css: "opacity-0" });
  }

  transitionEnd() {}

  render() {
    const { children, element } = this.props;
    return (
      <div className={this.state.css} onTransitionEnd={this.transitionEnd}>
        {element || children}
      </div>
    );
  }
}

export default AnimatedPage;
