import { Divider, Layout } from "../../components";
import { AlertContextProvider } from "../../store/contexts/alert-context";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const Footer = () => {
  return (
    <div className="footer">
      <span>@2024 Envision Healthcare</span>
      <Divider />
      <span>eiir v1.1.6</span>
    </div>
  );
};

const Home = () => {
  return (
    <AlertContextProvider>
      <div id="home" className="home">
        <Sidebar />
        <Topbar />
        <section className="outlet-section">
          <Layout />
          <Footer />
        </section>
      </div>
    </AlertContextProvider>
  );
};

export default Home;
