import { useEffect, useRef } from "react";

export const useClickAway = (callback) => {
  const currRef = useRef(null);

  useEffect(
    () => {
      const handleClickOutside = ({ target }) => {
        // const currId = currRef.current.id;
        const clickedOutside =
          currRef.current && !currRef.current.contains(target);
        callback(clickedOutside);
      };

      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    },
    // eslint-disable-next-line
    [callback]
  );

  return currRef;
};

export default useClickAway;
