import axios from "axios";

/**
 {
    // `url` is the server path that will be used for the request
    url,
    // `params` are the URL parameters to be sent with the request
    params,
    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', 'DELETE', and 'PATCH'
    data,
    signal,
    // `withCredentials` indicates whether or not cross-site Access-Control requests
    // should be made using credentials
    withCredentials,
  } = requestConfig;
  
  let config = { headers, params, withCredentials };
  const response = await api.post(url, data, config, signal);
 */

export const Urls = {
  baseURL: `${process.env.REACT_APP_FASTAPI}${process.env.REACT_APP_ENV}`,
  baseExpress: `${process.env.REACT_APP_EXPRESS}${process.env.REACT_APP_ENV}`,
  conformance: {
    analysis: "/conformance/analysis",
    overview: "/conformance/overview",
  },
  actions: "/users/resources/actions",
  refresh: "/users/auth/refresh",
  forgotPassword: "/users/forgot-password",
  resetPassword: "/users/profile/password",
  suspend: "/users/suspend",
  stats: "/mirth/connectors/stats",
  servers: "/mirth/servers",
  dashboard: "/reports/dashboard",
  notifications: "/repo/notifications",
  location: {
    view: "/repo/facilities/:id",
  },
  profile: {
    create: "/users/profile",
    view: "/repo/settings/users/:id",
    user: "/repo/settings/users/profile",
  },
  reports: {
    users: "/reports/users",
    channels: "/reports/channels",
    feeds: "/reports/inventory/feeds",
  },
  search: {
    channels: "/mirth/channels/search",
    feeds: "/mirth/feeds/search",
    locations: "/repo/locations/search",
    users: "/users/search",
    services: "/mirth/services/search",
  },
  verify: "/users/verify",
};

export const headers = {
  "Access-Control-Allow-Origin": process.env.REACT_APP_ORIGIN,
  Accept: "application/json",
  "Content-Type": "application/json;charset=utf-8",
};

// for base repository requests
export const axiosPrivate = axios.create({
  baseURL: Urls.baseURL,
  headers,
  withCredentials: true,
});

// for conformance API request only
export const axiosExpress = axios.create({
  baseURL: Urls.baseExpress,
  withCredentials: true,
  headers: {
    ...headers,
    "Content-Type": "multipart/form-data",
  },
});

const apiClient = axios.create({ baseURL: Urls.baseURL });
export default apiClient;

/**
 * Returns a JSON response with status and data
 * with { status: int, data: obj }
 * @param {*} response: the fastAPI response object.
 * @returns custom JSON response
 */
export const JsonResponse = (response) => {
  return { status: response.status, data: response.data };
};

export function getTerms(filters) {
  const { searchTerm, page, exchange, service, limit, status, ...defaults } =
    filters;
  const terms = {
    pageSize: limit,
    searchTerm,
    exchange,
    service,
    page,
    status,
  };

  for (let [key, valueObj] of Object.entries(defaults)) {
    for (let [k, v] of Object.entries(valueObj))
      if (v) (terms[key] = terms[key] || []).push(k);
  }

  return terms;
}

export function removeKey(filters, filterKey) {
  return Object.entries(filters || {}).reduce((reduceObj, [key, value]) => {
    if (value) {
      let lowerValue = value.toString().toLowerCase();
      if (lowerValue !== filterKey.toLowerCase()) reduceObj[key] = value;
    }
    return reduceObj;
  }, {});
}

export function handleSearch(data) {
  if (!data) return { results: [], total: 0 };

  const { entry, total } = data;
  window.scrollTo({ top: 100, behavior: "smooth" });

  return { results: entry.results, total };
}
