import { useState } from "react";
import {
  MdCheckCircleOutline as CheckedIcon,
  MdRadioButtonUnchecked as UncheckedIcon,
} from "react-icons/md";
import {
  invalidateChar,
  lowerCase,
  minLength,
  number,
  specialChar,
  upperCase,
} from "../../form/validation-rules";

const validationRules = [
  minLength(6, false),
  lowerCase(),
  upperCase(),
  specialChar(),
  number(),
];

const init = () =>
  validationRules.map(({ name, description }) => {
    return { name, description };
  });

const useRequirements = () => {
  const [checks, setChecks] = useState(init());
  const [error, setError] = useState(null);

  const handleError = (message = null) => setError(message);

  const handleClear = () => {
    setChecks(init());
    handleError();
  };

  const handleValidate = (value) => {
    const newChecks = validationRules.map(
      ({ name, description, validate }) => ({
        name,
        description,
        checked: validate(value),
      })
    );
    setChecks(newChecks);
  };

  const satisfied = Object.values(checks).every((obj) => obj.checked);

  const handleVerify = (value, sameValue) => {
    return !satisfied
      ? "New password does not meet Password Criteria. Please try again!"
      : !sameValue
      ? "New and Confirm passwords do not match. Please try again!"
      : !invalidateChar(value)
      ? "The new password contains invalid characters."
      : null;
  };

  return {
    checkList: (
      <ul className="text-sm whitespace-nowrap space-y-0.5">
        {checks.map(({ name, description, checked }) => (
          <li key={name} className="flex items-center space-x-2">
            <span>
              {checked ? (
                <CheckedIcon color="green" size={18} />
              ) : (
                <UncheckedIcon color="gray" size={18} />
              )}
            </span>
            <span className={checked ? "text-neutral-400" : null}>
              {description}
            </span>
          </li>
        ))}
      </ul>
    ),
    error,
    onVerify: handleVerify,
    onValidate: handleValidate,
    onClear: handleClear,
    onResetError: handleError,
  };
};

export default useRequirements;
