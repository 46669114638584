import { useCallback } from "react";

const _waitTime = 4;

const delayEvent = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Exponential backoff retry where operations are retried
// by increasing the wait times for a number of retry attempts.
function waitInSecs(retries) {
  if (retries === null) return null;
  const waitInMilliSecs = Math.pow(2, retries) * _waitTime;
  return waitInMilliSecs * 1000;
}

const useDelay = () => {
  const delay = useCallback((retries, callback) => {
    if (retries === null) return;

    let ms = waitInSecs(retries);

    void delayEvent(ms).then(() => {
      if (callback) callback();
    });
  }, []);

  return { delay };
};

export default useDelay;
export { delayEvent };
