import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { loadPage } from "../components";
import { useAuth, useCommon } from "../store/hooks";

const PersistLogin = () => {
  const { auth, storage, isRefreshing, verifyRefreshToken } = useAuth();
  const { loadCommon } = useCommon();
  const _isMounted = useRef(false);

  useEffect(() => {
    if (_isMounted.current) return;
    _isMounted.current = true;

    if (_isMounted && storage.persist && !(auth?.token || isRefreshing))
      verifyRefreshToken()
        .then(() => loadCommon())
        .finally(() => (_isMounted.current = false));
    // eslint-disable-next-line
  }, []);

  return !(storage.persist && isRefreshing) ? <Outlet /> : loadPage();
};

export default PersistLogin;
