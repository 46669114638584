import { createContext, useCallback, useState } from "react";
import apiClient, { headers, JsonResponse, Urls } from "../axios";
import { useLocalStorage, useNavigateHistory } from "../hooks";

const AuthContext = createContext({});

export const Role = {
  Analyst: 1,
  Manager: 2,
  Admin: 3,
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [isRefreshing, setIsRefresing] = useState(false);
  const { loginRedirect } = useNavigateHistory();
  const [storage, setStorageItem] = useLocalStorage({
    persist: false,
    eiirEmail: null,
  });

  const login = async (user, pwd) => {
    // To post the FormData using axios,
    // you need specify in header that you are sending FormData,
    // for that content-type should be multipart/form-data.
    try {
      let config = {
        headers,
        withCredentials: true,
        auth: { username: user, password: pwd },
      };
      const resp = await apiClient.post(
        "/users/auth",
        { username: user, password: pwd },
        config
      );

      return JsonResponse(resp);
    } catch ({ code, response }) {
      const { data, status } = response || {
        data: { detail: "(500) Internal Server Error" },
      };

      return code === "ERR_NETWORK"
        ? {
            status: status || 503,
            code: code || "Network Error",
            message: "Repository Service Unavailable",
          }
        : {
            status,
            message: data.detail,
          };
    }
  };

  const logout = useCallback(() => {
    setAuth({ loggedOut: true });
    apiClient.post("/users/logout", {}, { withCredentials: true });
  }, []);

  const verifyRefreshToken = useCallback(
    async (callback = null) => {
      try {
        setIsRefresing(true);
        const { data } = await apiClient.get(Urls.refresh, {
          headers,
          withCredentials: true,
        });

        setAuth({ ...data, isRefreshToken: true });
        if (callback) callback();
      } catch {
        loginRedirect();
      } finally {
        setIsRefresing(false);
      }
    },
    [loginRedirect]
  );

  const parseJWT = (accessToken) => {
    try {
      const jwtDecoded = atob(accessToken.split(".")[1]);
      return JSON.parse(jwtDecoded);
    } catch ({ response }) {
      if (response.status === 403) console.log(response.data);
      return {};
    }
  };

  const isAuthenticated = (token) => {
    if (!token) return false;

    const jwt = parseJWT(token.accessToken);
    const now = Math.ceil(Date.now() / 1000);
    return jwt.exp > now;
  };

  return (
    <AuthContext.Provider
      value={{
        setStorageItem,
        storage,
        auth,
        isRefreshing,
        isManager: auth?.rank >= Role.Manager,
        isAdmin: auth?.rank === Role.Admin,
        isAuthenticated,
        setAuth,
        login,
        logout,
        parseJWT,
        verifyRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
