import { useCallback } from "react";
import { Modal } from "../../components";
import useOpenState from "./use-openstate";

const useModal = (Component, props) => {
  const { isOpen, dismiss, showWindow } = useOpenState();
  const { onClose, ...rest } = props;

  const showModal = useCallback(() => {
    showWindow();
  }, [showWindow]);

  const handleClose = useCallback(() => {
    onClose();
    dismiss();
  }, [onClose, dismiss]);

  return {
    modal: (
      <Modal isOpen={isOpen} onClose={dismiss}>
        <Component onClose={handleClose} {...rest} />
      </Modal>
    ),
    showModal,
  };
};

export default useModal;
