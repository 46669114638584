import { MdOutlineCodeOff as TermedIcon } from "react-icons/md";
import { TbCards as LocationIcon } from "react-icons/tb";

export const orgConfig = [
  {
    key: "icon",
    render: (_) => (
      <div className="py-2.5 my-1.5 ml-2.5 flex-ij w-6 h-6">
        <LocationIcon size={16} />
      </div>
    ),
  },
  {
    label: "Hospital (facility)",
    render: (location) => location.name,
  },
  {
    label: "Facility ecode",
    render: (location) => location.ecode,
  },
  {
    label: "COCID",
    render: (location) => location.cocid,
  },
  {
    label: "Service Line(s)",
    render: (location) => {
      const notes = location.specialty.map((s, i) => {
        let note = s.display;
        const key = `${s.display}.${i}`;

        if (s.period && s.period.end) {
          return (
            <li className="flex items-center space-x-2" key={key}>
              <span>
                <TermedIcon size={14} color="#CC0000" />
              </span>
              <span>{note}</span>
            </li>
          );
        }

        return (
          <li className="ml-0.5" key={key}>
            {note}
          </li>
        );
      });

      return <ol className="m-1 py-1.5 list-disc list-inside">{notes}</ol>;
    },
  },
  {
    label: "Decommissioned",
    render: (location) => {
      const terms = location.specialty.map((s, i) => {
        return <li key={`term-${i}`}>{s?.period?.end || "..."} </li>;
      });

      return (
        <ol className="m-1 py-1.5 list-none grid grid-cols-1 justify-start">
          {terms}
        </ol>
      );
    },
  },
];
