export const initialChecks = {
  required: {
    label: "Required fields only",
    description:
      "EIIR may not raise an error due to the presence of a required element, but raise an error due the absence of a required element.",
  },
  standard: {
    label: "Standard codes",
    checked: true,
    description:
      "Check only HL7 defined, table-based (set of) values defined and published by HL7",
  },
  repeating: {
    label: "Repeating fields",
    checked: true,
    description: "Check if element is allowed to repeat",
  },
  maxAllowed: {
    label: "Max characters allowed",
    description:
      "The length of the field within a segment shall accurately reflect the maximum allowed length for the field within this segment definition",
  },
  custom: { label: "Ignore custom segment" },
};
export const messageConfig = [
  {
    label: "SEQ",
    render: (message) => (
      <p className="text-center py-1.5 px-3">{message.index}</p>
    ),
    align: "text-center",
    width: 76,
  },
  {
    label: "Segment",
    render: (message) => {
      const fields = message.segment.split("|");
      const header = fields.splice(0, 1)[0];
      const renderFields = fields.map((field, index) => {
        const text =
          field.length > 240
            ? `${field.substring(0, 240)} ... ${field.substring(
                field.length - 240
              )}`
            : field;
        return (
          <li key={`${header}.${index}`}>
            <span className="px-1">{text}</span>
          </li>
        );
      });

      return (
        <ol className="flex whitespace-nowrap divide-x divide-slate-500">
          <li
            key="MSH-0"
            className="mr-1 text-right w-6 text-primarydark font-mono font-semibold"
          >
            {header}
          </li>
          {renderFields}
        </ol>
      );
    },
  },
];
