import { useEffect, useState } from "react";
import { Button, IconButton, InputField } from "../../components";
import { isValidEmail, required } from "../../form/validation-rules";
import { useEiirForm, useIsMounted, useToast } from "../../store/hooks";
import api, { Urls, headers } from "../../store/axios";
import useForceUpdate from "../../store/hooks/use-forceupdate";
import { FiCornerUpLeft as LeftIcon } from "react-icons/fi";
import { useEiirMutation } from "../../repo-query";

const forgotConfig = {
  fguser: {
    rules: [isValidEmail("Username"), required("Username")],
  },
};

export const ForgotPassword = ({ onBackToLogin, onRequested }) => {
  const [requestedUser, setRequestedUser] = useState({});
  const { element: toastEl, warn } = useToast("pwd-request");
  const { forceUpdate } = useForceUpdate();
  const isMounted = useIsMounted();

  const { register, resetForm, handleSubmit } = useEiirForm(forgotConfig);

  useEffect(() => {
    if (isMounted()) {
      resetForm();
      forceUpdate();
    }
    // eslint-disable-next-line
  }, [forceUpdate]);

  const resetOptions = {
    mutationKey: ["forgetPassword"],
    mutationFn: async (email) =>
      await api.put(Urls.forgotPassword, { email }, { headers }),
    onSuccess: ({ data }) => setRequestedUser(data),
    onError: (error) => warn(error.message),
  };

  const mutation = useEiirMutation(resetOptions);

  const onSubmit = async (data) => mutation.mutate(data.fguser);

  return (
    <form id="forgot-pwdform" onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        {requestedUser.name ? (
          <>
            <p className="header">Hi {requestedUser.name},</p>
            <div className="text-center">
              <p>We have sent a request to reset the password for:</p>
              <p className="text-center text-envision tracking-normal mb-4">
                {requestedUser.email}
              </p>
              A System Administrator will contact you soon with instructions on
              how to unlock your account.
              <br />
            </div>
            <Button
              content="Back to login"
              onClick={() => onRequested(requestedUser.email)}
            />
          </>
        ) : (
          <>
            <p className="header">Trouble Logging In?</p>
            <p className="text-sm text-center text-neutral-700 tracking-tight my-1 mx-5">
              Tell us your username to request a password reset and an
              Administrator will get you back on track in no time.
            </p>
            {toastEl}
            <InputField
              {...register("fguser")}
              label="Username"
              helperText="Reset Requested For"
            />
            <Button
              type="submit"
              content="Request Password Reset"
              loading={mutation.isLoading}
            />
            <IconButton
              icon={<LeftIcon />}
              position="left"
              label="Back to log in"
              onClick={() => onBackToLogin(false)}
            />
          </>
        )}
      </fieldset>
    </form>
  );
};
