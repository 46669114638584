import { useCallback } from "react";
import { Button, Divider, Dropdown, InputField } from "../../components";
import { ipCheck, isPort, required } from "../../form/validation-rules";
import { useEiirMutation } from "../../repo-query";
import { useAxiosPrivate, useEiirForm, useToast } from "../../store/hooks";

function getMessage(id) {
  return {
    instance:
      "The name of the Mirth Connect instance where all the Mirth Channels will be accessible.",
    ipaddress:
      "Local area network that contains broadcasts within itself and only hosts that belong to that VLAN will see those broadcasts.",
    hostname:
      "The URL which allows access to the Mirth Connect Server via a well-defined API.",
    port: "This port is used to communicate with the NextGen Connect Integration Engine Server.",
  }[id];
}

const landingPoints = ["Atlanta Data Center", "AWS Cloud", "Other"];

function init(server) {
  const initialState = {
    instance: {
      value: server.name,
      rules: [required("Server Name")],
    },
    hostname: {
      value: server.hostname,
      rules: [required("Hostname")],
    },
    ipaddress: {
      value: server.ipaddress,
      rules: [required("IP Address"), ipCheck()],
    },
    port: {
      value: server.port,
      rules: [required("Port Number"), isPort()],
    },
    dilp: { value: server.dilp },
  };

  return initialState;
}

export const ServerForm = ({
  server = {},
  isUpdate,
  onClose,
  onInvalidate,
}) => {
  const { element: toastEl, warn, info } = useToast();
  const { register, handleSubmit } = useEiirForm(init(server));
  const axiosPrivate = useAxiosPrivate();

  let mutationFn;
  const mutationKey = ["createServer"];

  if (server.serverId) {
    mutationKey.push({ serverId: server.serverId });
    mutationFn = async (data) => {
      const resp = await axiosPrivate.put(
        `/mirth/servers/${server.serverId}`,
        data
      );
      return resp.data;
    };
  } else {
    mutationFn = async (data) => {
      const resp = await axiosPrivate.post("/mirth/servers", data);
      return resp.data;
    };
  }

  const mutator = useEiirMutation({
    mutationKey,
    mutationFn,
    onSuccess: (data) => {
      onInvalidate("servers", (oldState) => {
        if (isUpdate) {
          return oldState.map((el) =>
            el.serverId === data.serverId ? data : el
          );
        } else {
          return [...oldState, data];
        }
      });
      onClose();
    },
    onError: ({ message }) => warn(message),
  });

  const handleFocus = useCallback((target) => info(getMessage(target)), [info]);

  const onSubmitServer = async (data) => {
    mutator.mutate(data);
  };

  return (
    <form id="server-form" onSubmit={handleSubmit(onSubmitServer)}>
      <section className="drawer-section">
        <fieldset className="fs-server">
          <div className="flex3">
            <label htmlFor="instance">
              Server Name
              <InputField
                {...register("instance")}
                helperText="Mirth Connect Instance"
                onFocus={() => handleFocus("instance")}
                width={248}
              />
            </label>
            <div className="mt-4">
              <InputField
                {...register("ipaddress")}
                helperText="VLAN or IP Address (e.g.: 127.0.0.1)"
                onFocus={() => handleFocus("ipaddress")}
                outline
                required
              />
            </div>
          </div>
          <div className="flex3">
            <label>
              Client Hostname
              <InputField
                {...register("hostname")}
                helperText="Hostname or API endpoint"
                onFocus={() => handleFocus("hostname")}
                required
                width={280}
              />
            </label>
            <label>
              Port
              <InputField
                {...register("port")}
                helperText="Administrator Port"
                onFocus={() => handleFocus("port")}
                required
              />
            </label>
          </div>
          <div>
            <label>
              DILP (optional)
              <Dropdown
                id="dilp"
                {...register("dilp", { type: "select" })}
                helperText="Data Ingestion Landing Point"
                sourceItems={landingPoints}
              />
            </label>
          </div>
          {toastEl}
        </fieldset>

        <div className="flex space-x-2 mb-1">
          <Button
            outline
            content="Cancel"
            sx={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
            onClick={() => onClose(false)}
          />
          <Button
            type="submit"
            content={`${isUpdate ? "Update" : "Add"} Server`}
            sx={{ paddingTop: 10 }}
            loading={mutator.isLoading}
          />
          {isUpdate && (
            <>
              <Divider />
              <Button light content="Disconnect Server" />
            </>
          )}
        </div>
      </section>
    </form>
  );
};
