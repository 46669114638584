import { useMemo } from "react";
import { BsFiletypeHtml as HtmlIcon } from "react-icons/bs";
import {
  GrDocumentPdf as PdfIcon,
  GrDocumentRtf as RtfIcon,
} from "react-icons/gr";
import { RxOpenInNewWindow as OpenNoteIcon } from "react-icons/rx";
import { TbJpg as ImageIcon, TbBrandXing as OtherIcon } from "react-icons/tb";
import { Button, Table } from "../../components";

const Icons = {
  HTML: HtmlIcon,
  IMAGE: ImageIcon,
  PDF: PdfIcon,
  RTF: RtfIcon,
  XHTML: HtmlIcon,
};

const EncodedDocument = ({ notes, onView }) => {
  const noteConfig = useMemo(
    () => [
      {
        key: "noteIndex",
        render: () => (
          <span className="relative text-blue-800 flex items-center justify-center ml-4 w-8 h-8 bg-blue-100 rounded-full ring-8 ring-white">
            <OtherIcon size={20} />
          </span>
        ),
      },
      { label: "Filler No.", render: (note) => note.fillerNo },
      { label: "Service Id", render: (note) => note.serviceId },
      { label: "Status", render: (note) => note.status },
      { label: "Observer", render: (note) => note.observer },
      { label: "Size", render: (note) => note.size },
      {
        label: "Subtype",
        render: (note) => {
          const Icon =
            note.subtype in Icons ? Icons[note.subtype] : OpenNoteIcon;
          return (
            <div className="relative flex-ij border bg-slate-200 w-full h-9 rounded">
              <Icon size={22} />
            </div>
          );
        },
        align: "text-center",
      },
      {
        key: "view",
        render: (note) => {
          return <Button content="Open" onClick={() => onView(note)} />;
        },
      },
    ],
    [onView]
  );

  return <Table loose dataSource={notes} columns={noteConfig} />;
};

export default EncodedDocument;
