import { useCallback, useMemo, useState } from "react";
import { GrStatusCritical as StatusIcon } from "react-icons/gr";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import { MdFitbit as FeedIcon } from "react-icons/md";
import { TbExchange as ExchangeIcon } from "react-icons/tb";
import { IconButton, Indicator, Panel, PanelHeader } from "../../components";
import { useCommon, useTable } from "../../store/hooks";
import { FeedCharts } from "./FeedCharts";
import { FeedSummary } from "./FeedSummary";

const Feeds = () => {
  const [serviceId, setServiceId] = useState(null);
  const { exchangeTypes } = useCommon();

  const handleClose = useCallback((id = null) => setServiceId(id), []);

  const columns = useMemo(() => {
    return [
      {
        key: "code",
        render: (entry) => (
          <div className="hl7-avatar">
            <div className="inline-flex my-4 w-11 h-9 items-center justify-center border shadow rounded-sm border-slate-300 bg-slate-200">
              <span className="text-[12px] text-slate-500 font-semibold leading-none">
                {entry.hl7}
              </span>
            </div>
          </div>
        ),
      },
      {
        label: "Source Channel Feed",
        render: (entry) => (
          <div className="py-1 leading-tight">
            <p>{entry.source}</p>
            <span className="text-sm text-slate-500">{entry.uuid}</span>
          </div>
        ),
        sortValue: (entry) => entry.source,
      },
      {
        label: "Exchange Type",
        render: (entry) => (
          <div className="py-1 leading-tight">
            <p>{entry.exchange}</p>
            <span className="text-sm text-slate-500">
              {entry.dataformat} {entry.direction || "Inbound"}
            </span>
          </div>
        ),
      },
      {
        label: "Server Origin",
        render: (entry) => (
          <div className="py-1 leading-tight">
            <p>{entry.server}</p>
            <div className="text-sm text-slate-500 whitespace-nowrap">
              {entry.port && `Port ${entry.port}`}
            </div>
          </div>
        ),
        sortValue: (entry) => entry.server,
      },
      {
        label: "Status",
        render: (entry) => (
          <div className="flex-ij">
            <Indicator color={entry.active && "ok"} />
          </div>
        ),
        align: "text-center",
      },
      {
        key: "serviceView",
        render: (entry) => (
          <IconButton
            title="View Feed Summary"
            icon={<Icon size={18} />}
            sx={{ paddingRight: 10 }}
            onClick={() => handleClose(entry.id)}
          />
        ),
      },
    ];
  }, [handleClose]);

  const options = useMemo(
    () => ({
      service: {
        type: "operator",
        id: "service-fltr",
        label: "Channel Feed",
      },
      dx: {
        type: "select",
        id: "exchange-fltr",
        label: "Exchange Type",
        icon: ExchangeIcon,
        options: {
          field: "exchange",
          id: "filter-ex-form",
          title: "Filter by exchange type",
          subtitle: "Find a facility that has a specific data exchange type.",
          sources: Object.values((exchangeTypes || {}).map(({ type }) => type)),
        },
      },
      status: {
        type: "bool",
        id: "status-fltr",
        field: "status",
        label: "Status",
        icon: StatusIcon,
      },
    }),
    [exchangeTypes]
  );

  const tableConfig = { resource: "feeds", options, columns };
  const tableElement = useTable(tableConfig);

  return (
    exchangeTypes.length > 0 && (
      <div className="w-full space-y-4">
        <Panel>
          <PanelHeader
            PanelIcon={FeedIcon}
            title="DIS Feeds"
            subtitle="Integration Services"
          />
        </Panel>
        <FeedCharts />
        {serviceId ? (
          <FeedSummary serviceId={serviceId} onClose={handleClose} />
        ) : (
          tableElement
        )}
      </div>
    )
  );
};

export default Feeds;
