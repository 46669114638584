import { useMemo } from "react";
import { SiMoleculer as ChannelsIcon } from "react-icons/si";
import { TbSettingsCheck as CheckIcon } from "react-icons/tb";
import { Panel, PanelHeader, IconButton, Indicator } from "../../components";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import { MdCodeOff as OffIcon, MdGraphicEq as OnIcon } from "react-icons/md";
import { useCommon, useDrawer, useTable } from "../../store/hooks";
import { ChannelsChart } from "./ChannelsChart";
import { ChannelSummary } from "./ChannelSummary";

const Channels = () => {
  const { drawer, openDialog } = useDrawer("channels-portal");
  const { sources } = useCommon();

  const options = useMemo(() => {
    return {
      channels: {
        type: "operator",
        id: "channels-fltr",
        label: "Channel Name",
      },
      connectors: {
        type: "check",
        id: "channel-conn-flter",
        field: "connectors",
        label: "Source Connectors",
        icon: CheckIcon,
        options: {
          title: "Source Connector Types",
          values: sources,
        },
      },
    };
  }, [sources]);

  const columns = useMemo(
    () => [
      {
        key: "activecol",
        render: (channel) =>
          channel.is_enabled ? (
            <div className="flex-ij ml-2">
              <div className="channelcol">
                <OnIcon size={20} />
              </div>
            </div>
          ) : (
            <div className="flex-ij">
              <OffIcon size={20} />
            </div>
          ),
      },
      {
        label: "Channel",
        render: (channel) => (
          <div className="fcoly25">
            <span className="text-sm text-gray-500">{channel.uuid}</span>
            <span>{channel.name}</span>
          </div>
        ),
        sortValue: (channel) => channel.name,
      },
      {
        label: "Connector",
        render: (channel) => (
          <div className="fcoly25">
            <span className="text-sm text-gray-500">
              {channel.dataformat.name}
            </span>
            <span>{channel.source_connector}</span>
          </div>
        ),
      },
      {
        label: "Server",
        render: (channel) => channel.mirthserver.name,
      },
      {
        label: "Enabled",
        render: (channel) => (
          <div className="flex-ij">
            <Indicator color={channel.is_enabled && "ok"} />
          </div>
        ),
        align: "text-center",
      },
      {
        key: "channelview",
        render: (channel) => (
          <IconButton
            icon={<Icon size={18} />}
            sx={{ paddingRight: 16 }}
            title="View Channel Summary"
            onClick={() =>
              openDialog({
                element: ChannelSummary,
                channel,
              })
            }
          />
        ),
      },
    ],
    [openDialog]
  );

  const tableConfig = { resource: "channels", columns, options };
  const tableElement = useTable(tableConfig);

  return (
    sources && (
      <div className="flex flex-col space-y-3">
        <Panel>
          <PanelHeader
            PanelIcon={ChannelsIcon}
            title="Mirth Channels"
            subtitle="Connectors"
          />
        </Panel>
        <ChannelsChart />
        {tableElement}
        {drawer}
      </div>
    )
  );
};

export default Channels;
