export function PanelHeader({ children, PanelIcon, title, subtitle }) {
  return (
    <div className="panelheader">
      {children || (
        <>
          <span className="hpanelicon">{PanelIcon && <PanelIcon />}</span>
          <div>
            <p className="sm-text">{subtitle}</p>
            <p className="t23m">{title}</p>
          </div>
        </>
      )}
    </div>
  );
}

export function PanelBody({ className, children }) {
  return <div className={`panelbody ${className}} ""`}>{children}</div>;
}

export function PanelFooter({ children }) {
  return <div className="panelfooter">{children}</div>;
}

const Panel = ({ title, children, className }) => {
  return (
    <div className={`hpanel ${className}`}>
      {title && (
        <PanelHeader>
          <p className="title">{title}</p>
        </PanelHeader>
      )}
      {children}
    </div>
  );
};

export default Panel;
