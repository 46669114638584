import { useEffect, useState } from "react";
import { SiOkta } from "react-icons/si";
import {
  Button,
  Checkbox,
  Divider,
  HorizontalRuler,
  InputField,
} from "../../components";
import { required } from "../../form/validation-rules";
import { useAttempt, useAuth, useEiirForm, useToast } from "../../store/hooks";

const lockWarning = (
  <>
    <p>
      This account has reached the maximum number of failed login attempts and
      has been locked.
    </p>
    <p className="mt-3 text-neutral-600">
      Please contact your EIIR Administrator!
    </p>
  </>
);

function init(email) {
  const controls = {
    username: { value: email, rules: [required("Username")] },
    password: { value: "dis123$", rules: [required("Password")] },
  };

  return controls;
}

export const LoginForm = ({ user, onContinue, onForget, onLockUser }) => {
  const [loading, setLoading] = useState(false);
  const [userCanSubmit, setUserCanSubmit] = useState(true);
  const { element: toastEl, warn } = useToast("login-toast");
  const { login, storage, setStorageItem } = useAuth();
  const { isBelowThreshold, canRetry, resetCount, recordMistake } =
    useAttempt(7);

  const { values, register, handleSubmit, resetForm } = useEiirForm(
    init(storage.eiirEmail)
  );

  const currUser = user || values.username;

  const handleForget = () => {
    setUserCanSubmit(true);
    onForget(true);
  };

  const handleFailedLogin = async (user, message) => {
    if (isBelowThreshold) {
      const remaining = canRetry(user);
      if (remaining) {
        warn(
          <>
            {message} <p>Please try again or select Forgot Password below.</p>
            <p className="text-neutral-600 mt-2">
              Remaining attempts: ( {remaining} )
            </p>
          </>
        );
      } else {
        // user has exceeded maximum attempts
        const userLocked = await onLockUser(user, "failed login att");
        if (userLocked) {
          warn(lockWarning);
          resetCount();
          setUserCanSubmit(false);
        }
      }

      recordMistake(user);
    }
  };

  const onSubmit = async ({ username, password }) => {
    if (!userCanSubmit) return;
    setLoading(true);

    try {
      const { status, data, message } = await login(username, password);
      switch (status) {
        case 200:
          setStorageItem("eiirEmail", storage.persist ? username : null);
          onContinue("login", data);
          resetForm();
          resetCount();
          break;
        case 401:
        case 404:
          handleFailedLogin(username, message);
          break;
        case 403:
          warn(lockWarning);
          setUserCanSubmit(false);
          break;
        default:
          warn(message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => setUserCanSubmit(true), [currUser]);

  return (
    <section>
      <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="okta">
          <label htmlFor="signOkta-btn">Sign In with</label>
          <Button circle id="signOkta-btn" icon={{ icon: SiOkta, size: 20 }} />
        </div>
        <HorizontalRuler span="Or" />
        {toastEl}
        <InputField {...register("username")} />
        <InputField {...register("password", { type: "password" })} />
        <Checkbox
          id="persist"
          label="Remember me!"
          checked={storage.persist}
          onChange={({ target }) => setStorageItem("persist", target.checked)}
        />
        <div className="flex-i">
          <Button
            type="submit"
            id="loginform-submit"
            content="SIGN IN"
            loading={loading}
          />
          <Divider sx={{ marginLeft: 16 }} />
          <Button
            outline
            id="forgotpwd-btn"
            content="Forgot Password?"
            onClick={handleForget}
          />
        </div>
      </form>
    </section>
  );
};
