import { useEffect, useRef } from "react";

/**
 * Custom hook that handles timeouts in React components
 * using the setTimeout API.
 *
 * @param {*} callback
 * @param {*} delay
 */
const useTimeout = (callback, delay) => {
  const savedCallback = useRef(callback);

  // Set up the timeout
  useEffect(() => {
    // do not schedule if no delay is specified
    // Note: 0 is a valid value for delay
    if (!(delay || delay === 0)) return;

    const timer = setTimeout(() => savedCallback.current(), delay);
    return () => clearTimeout(timer);
  }, [delay]);
};

export default useTimeout;
