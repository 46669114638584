import { Component } from "react";

class DataExport extends Component {
  constructor(props) {
    super(props);

    this.exportJSON = this.exportJSON.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }
  render() {
    return;
  }

  downloadFile = (data, filename, fileType) => {
    // Create a blob with the data to download as a file
    const blob = new Blob([data], { type: fileType });

    // Create an anchor element
    // and dispatch a click envent to trigger the download
    const a = document.createElement("a");

    const clickEvnt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    const url = window.URL.createObjectURL(blob);

    a.download = filename;
    a.href = url;
    a.dispatchEvent(clickEvnt);
    a.remove();

    // Revoke the Blob URL to release resources
    window.URL.revokeObjectURL(url);
  };

  exportJSON = (data, filename) => {
    console.log("exporting to JSON");
  };

  exportCSV = (data, filename) => {
    let headers = Object.keys(data[0]).join(",");
    // Convert data to a csv
    const dataCsv = [
      headers,
      ...data.reduce((reduceArr, datum) => {
        reduceArr.push(Object.values(datum).join(","));
        return reduceArr;
      }, []),
    ];

    this.downloadFile(dataCsv.join("\n"), filename, "text/csv");
  };

  exportMultiCSV = (dataCsv, filename) => {
    this.downloadFile(dataCsv.join("\n"), filename, "text/csv");
  };
}

export default DataExport;
