import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Panel, PanelBody, PanelHeader, Skeleton } from "../../components";

const CustomServiceTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { payload: data } = payload[0];
    const locations = Object.entries(data.feeds).map(([k, v]) => (
      <li key={`${data.code}-${k}`}>{`${k}: ${v}`}</li>
    ));

    return (
      <div className="bg-white border border-dotted border-envision p-4 text-sm">
        <p className="font-semibold">Service line: {data.code}</p>
        <p className="text-[#8884d8]">{`${data.total} location${
          data.total > 1 ? "s" : ""
        }`}</p>
        <ol className="mt-2 pt-2">{locations}</ol>
      </div>
    );
  }

  return null;
};

// Service lines using a simple radar chart
export const ServiceCard = ({ feeds }) => {
  return (
    <Panel className="w-[34%]">
      <PanelHeader>
        <div className="-m-3">
          <span className="text-[16px] text-neutral-700 font-semibold tracking-tight">
            Service Lines
          </span>
          <p className="text-[15px] text-neutral-500 leading-2">
            Provided across all hospital locations
          </p>
        </div>
      </PanelHeader>
      <PanelBody>
        <div className="w-full h-[18rem]">
          {feeds ? (
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart data={feeds} outerRadius={128}>
                <PolarGrid />
                <Tooltip content={<CustomServiceTooltip />} />
                <PolarAngleAxis
                  dataKey="code"
                  tick={{ fontSize: 12, fontWeight: "bold" }}
                />
                <PolarRadiusAxis angle={60} tick={{ fontSize: 12 }} />
                <Radar
                  name="Services"
                  dataKey="total"
                  stroke="00884d8"
                  fill="#8884d8"
                  fillOpacity={0.7}
                />
              </RadarChart>
            </ResponsiveContainer>
          ) : (
            <div className="w-full flex-ij">
              <Skeleton widget />
            </div>
          )}
        </div>
      </PanelBody>
    </Panel>
  );
};
