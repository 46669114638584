import {
  addEdge,
  Background,
  ConnectionLineType,
  Controls,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import { memo, useCallback, useEffect } from "react";
import {
  AnnotationNode,
  CustomNode,
  PatientNode,
  ServicelineNode,
  SourcesNode,
} from "./CustomNode";

const nodeTypes = {
  origin: CustomNode,
  annotation: AnnotationNode,
  patNode: PatientNode,
  serviceNode: ServicelineNode,
  sNode: SourcesNode,
};

/*
  defined outside of the component
  to prevent re-rendering every time the FlowRender renders
  */

const FlowRender = ({
  initialNodes,
  initialEdges,
  fitView,
  zoom,
  showControls,
  children,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const handleClick = useCallback((event, node) => {
    console.log(node);
  }, []);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialNodes, initialEdges, setNodes, setEdges]);

  return (
    <ReactFlow
      style={{ width: "100%", height: "100%" }}
      attributionPosition="bottom-left"
      connectionLineType={ConnectionLineType.SmoothStep}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      fitView={fitView}
      defaultViewport={{ x: 40, y: 20, zoom: zoom || 1 }}
      zoomOnScroll={false}
      onNodeClick={handleClick}
    >
      <Background color="#aaa" gap={12} variant="dots" />
      {showControls && false && <Controls />}
      {children}
    </ReactFlow>
  );
};

export default memo(FlowRender);
