import { useCallback } from "react";
import { BiServer as NewIcon } from "react-icons/bi";
import { IoMdCube as ServersIcon } from "react-icons/io";
import { Panel, PanelHeader, TabContent, Tabs } from "../../components";
import { useEiirQuery, useEiirQueryClient } from "../../repo-query";
import { Urls } from "../../store/axios";
import { useAxiosPrivate } from "../../store/hooks";
import ServerList from "./ServerList";

const serverKey = ["servers"];

const Servers = () => {
  const axiosPrivate = useAxiosPrivate();
  const eiirQClient = useEiirQueryClient();
  const getServers = async () => await axiosPrivate(Urls.servers);

  const { isLoading, data } = useEiirQuery({
    queryKey: serverKey,
    queryFn: getServers,
    staleTime: 30,
  });

  const setMutationData = useCallback((mutationKey, updater) => {
    eiirQClient.setQueryData(mutationKey, updater);
    // eslint-disable-next-line
  }, []);

  const handleInvalidate = useCallback(() => {
    eiirQClient.invalidateQueries(serverKey);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="server-container">
      <Panel>
        <PanelHeader
          PanelIcon={ServersIcon}
          title="Server Manager"
          subtitle="Mirth Connect"
        />
      </Panel>

      <Tabs>
        <TabContent label="Info">
          <div className="slist">
            <ul>
              {isLoading || !data ? (
                <li key="loader">
                  <div className="h-full flex-ij-col">
                    <div className="newserver">
                      <span className="avatar animate-bounce">
                        <NewIcon size={48} />
                      </span>
                    </div>
                  </div>
                </li>
              ) : (
                <ServerList
                  data={data}
                  loading={isLoading}
                  onInvalidate={handleInvalidate}
                  onMutation={setMutationData}
                />
              )}
            </ul>
          </div>
        </TabContent>
      </Tabs>
    </div>
  );
};

export default Servers;
