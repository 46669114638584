import { createQuery, updaters } from "./createQuery";

export class EiirQueryClient {
  constructor() {
    this.queries = [];
  }

  setQueryData = (queryKey, updater) => {
    // update the old data immutably
    const fullQuery = this.getQuery({ queryKey: [queryKey], create: false });
    const cache = updater(fullQuery.state.data);
    fullQuery.setState((oldState) => updaters.onSuccess(oldState, cache));
  };

  invalidateQueries = (queryKey) => {
    this.getQuery({ queryKey }).fetchQuery();
  };

  /** Identify a query
   * the query key is an array that uniquely identifes a specific.
   */
  getQuery = ({ queryKey, queryFn, create = true }) => {
    const queryHash = JSON.stringify(queryKey);

    let query = this.queries.filter(
      (query) => query.queryHash === queryHash
    )[0];

    if (!query && create) {
      // create a new query
      query = createQuery(queryKey, queryFn);
      this.queries.push(query);
    }

    return query;
  };
}
